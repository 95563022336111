import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { updateVentureAvatarsService } from '../../../../../services/Ventures/UpdateVentureAvatarsService';
import { createVenturesService } from '../../../../../services/Ventures/CreateVenturesService';
import { translateError } from '../../../../../utils/errors';

type NewVentureFormData = {
  appstoreId?: string;
  bio?: string;
  cnpj: string;
  email: string;
  name: string;
  phone?: string;
  playstoreId?: string;
  ref: string;
};

const registerVentureFormSchema = Yup.object().shape({
  appstoreId: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cnpj: Yup.string()
    .required('CNPJ requerido')
    .length(14, 'Valor inválido, requerido 14 dígitos')
    .transform((_, originalValue) => originalValue.replace(/\D/g, '')),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  name: Yup.string().required('Nome requerido'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  playstoreId: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ref: Yup.string().required('Requerido'),
});

export const VentureRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(registerVentureFormSchema),
  });

  const { errors } = formState;

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewVenture: SubmitHandler<NewVentureFormData> = useCallback(
    async ({ appstoreId, bio, cnpj, email, name, phone, playstoreId, ref }) => {
      try {
        const venture = await createVenturesService({
          appstoreId,
          bio,
          cnpj,
          email,
          name,
          phone,
          playstoreId,
          ref,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateVentureAvatarsService({
            avatarData: formData,
            ventureId: venture.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O empreendimento foi cadastrado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/ventures/details', { ventureId: venture.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar o empreendimento, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewVenture)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar empreendimento
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 8]}>
              <MaskedInput
                label="Nome"
                error={errors.name}
                {...register('name')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 4]}>
              <MaskedInput
                label="Ref"
                mask="ref"
                textTransform="uppercase"
                error={errors.ref}
                {...register('ref')}
              />
            </GridItem>
          </Grid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />

            <MaskedInput
              label="CNPJ"
              mask="cnpj"
              error={errors.cnpj}
              {...register('cnpj')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="Appstore Id"
              error={errors.appstoreId}
              {...register('appstoreId')}
            />

            <MaskedInput
              label="Playstore Id"
              error={errors.playstoreId}
              {...register('playstoreId')}
            />
          </SimpleGrid>

          <MaskedInput
            label="Biografia"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
