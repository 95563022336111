import {
  IActivityItemBase,
  IActivityScheduleItemBase,
} from '../../models/activities';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import { IDetailedBooking } from './ShowBookingsService';

interface IUpdateBookingItemsProps {
  bookingId: string;
  items?: IActivityItemBase[];
  scheduleItems?: IActivityScheduleItemBase[];
}

export type IUpdatedBooking = IDetailedBooking;

export const updateBookingItemsService = async ({
  bookingId,
  items,
  scheduleItems,
}: IUpdateBookingItemsProps): Promise<IUpdatedBooking> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedBooking>(
    `/bookings/${bookingId}/items`,
    {
      items,
      scheduleItems,
    },
  );

  return data;
};
