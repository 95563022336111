import React, { createContext, useCallback, useState, useContext } from 'react';
import { IBookingPaymentBase } from '../models/bookings';
import {
  IDetailedBooking,
  IDetailedBookingActivityScheduleItem,
} from '../services/Bookings/ShowBookingsService';
import { maskDateTime, maskMoney } from '../utils/formatters/handleMask';

interface IBookingContextPayment extends IBookingPaymentBase {
  formattedUpdatedAt: string;
}

interface IBookingContextScheduleItem
  extends IDetailedBookingActivityScheduleItem {
  formattedPrice: string;
}

export interface IBookingContextState extends IDetailedBooking {
  formattedBookedDate: string;
  formattedBookingPrice: string;
  formattedCreatedAt: string;
  formattedDeletedAt?: string;
  formattedEndTime: string;
  formattedStartTime: string;
  payment?: IBookingContextPayment;
  scheduleItems: IBookingContextScheduleItem[];
}

interface IBookingContextData {
  booking?: IBookingContextState;
  handleBooking: (booking?: IDetailedBooking) => void;
}

const BookingContext = createContext<IBookingContextData>(
  {} as IBookingContextData,
);

const BookingProvider: React.FC = ({ children }) => {
  const [booking, setBooking] = useState<IBookingContextState>();

  const handleBooking = useCallback((bookingData?: IDetailedBooking) => {
    if (bookingData) {
      const [bookedYear, bookedMonth, bookedDay] =
        bookingData.bookedDate.split('-');

      setBooking({
        ...bookingData,
        formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
        formattedBookingPrice: bookingData.bookingPrice
          ? maskMoney(bookingData.bookingPrice)
          : '-',
        formattedCreatedAt: maskDateTime(bookingData.createdAt),
        formattedDeletedAt: bookingData.deletedAt
          ? maskDateTime(bookingData.deletedAt)
          : undefined,
        formattedEndTime: bookingData.endTime
          ? bookingData.endTime.slice(11, 16)
          : bookingData.activitySchedule.endTime.slice(0, 5),
        formattedStartTime: bookingData.startTime
          ? bookingData.startTime.slice(11, 16)
          : bookingData.activitySchedule.startTime.slice(0, 5),
        scheduleItems: bookingData.scheduleItems.map((scheduleItem) => ({
          ...scheduleItem,
          formattedPrice: scheduleItem.price
            ? maskMoney(scheduleItem.price)
            : '-',
        })),
        payment: bookingData.payment
          ? {
              ...bookingData.payment,
              formattedUpdatedAt: maskDateTime(bookingData.payment.updatedAt),
            }
          : undefined,
      });
    } else {
      setBooking(undefined);
    }
  }, []);

  return (
    <BookingContext.Provider
      value={{
        booking,
        handleBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

const useBooking = (): IBookingContextData => useContext(BookingContext);

export { BookingProvider, useBooking };
