import { useHistory } from 'react-router-dom';
import {
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
  Divider,
  useBreakpointValue,
  FlexProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  RiBookmarkLine,
  RiDeleteBinLine,
  RiEditLine,
  RiEyeLine,
  RiLoginCircleLine,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { isBefore } from 'date-fns';
import { translateError } from '../../../../../../../utils/errors';
import {
  IBookingContextState,
  useBooking,
} from '../../../../../../../hooks/booking';
import deleteBookingsService from '../../../../../../../services/Bookings/DeleteBookingsService';
import BookingDetailsUserInfo from './components/BookingDetailsUserInfo';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { useAuth } from '../../../../../../../hooks/auth';
import { CheckinListModal } from '../../../../../../../components/CheckinListModal';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { UserExperience } from '../../../../../../../models/users';
import {
  BookingPaymentStatus,
  PrettyBookingPaymentStatus,
} from '../../../../../../../models/bookings';
import { createBookingExternalPaymentsService } from '../../../../../../../services/Bookings/CreateBookingExternalPaymentsService';
import { PaymentConfirmationModal } from '../../../../../../../components/PaymentConfirmationModal';
import { BookingUpdateModal } from '../../../../Activities/ActivityDetails/components/ActivityDetailsData/components/ActivityDetailsTabs/components/BookingUpdateModal';

export const BookingDetailsInfo = (props: FlexProps): JSX.Element => {
  const toast = useToast();

  const { push } = useHistory();

  const { user: authenticatedUser } = useAuth();

  const { booking, handleBooking } = useBooking();

  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    xl: 'vertical',
  });

  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [isCheckinListModalVisible, setIsCheckinListModalVisible] =
    useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isBookingUpdateModalVisible, setIsBookingUpdateModalVisible] =
    useState(false);

  const handleTogglePaymentModal = useCallback(() => {
    setIsPaymentModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleCheckinListModal = useCallback(() => {
    setIsCheckinListModalVisible((prevState) => !prevState);
  }, []);

  let cancelBookingWarningMessage: string | undefined;

  const bookedDateTime = new Date(
    booking?.bookedDate.concat(`T${booking.activitySchedule.startTime}`) || '',
  );

  const isCancelable =
    !booking?.checkins.length &&
    isBefore(new Date(), bookedDateTime) &&
    !booking?.deletedAt;

  if (booking?.payment?.status === BookingPaymentStatus.PAYMENT_EXTERNAL) {
    cancelBookingWarningMessage =
      'Essa reserva foi paga externamente, o reembolso também deverá ser feito externamente.';
  }

  const handlePayment = useCallback(
    async (bookingState: IBookingContextState) => {
      try {
        const paydBooking = await createBookingExternalPaymentsService(
          bookingState.id,
        );

        handleBooking({
          ...bookingState,
          payment: paydBooking.payment,
          user: bookingState.user,
        });

        toast({
          title: 'Transação concluída com sucesso',
          description: 'A transação foi efetuada corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha na transação',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao efetuar a transação, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        handleTogglePaymentModal();
      }
    },
    [handleBooking, handleTogglePaymentModal, toast],
  );

  const handleDeleteBooking = useCallback(async () => {
    if (booking?.id) {
      try {
        await deleteBookingsService(booking.id);

        toast({
          title: 'Cancelada com sucesso',
          description: 'A reserva foi cancelada corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/bookings');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao cancelar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cancelar a reserva, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [booking?.id, push, toast]);

  return (
    <>
      {!!booking &&
        authenticatedUser.featureGroup.features.some((feature) =>
          [
            'BOOKING_CHECKIN_FULL_ACCESS',
            'BOOKING_CHECKIN_READ_ACCESS',
          ].includes(feature.key),
        ) && (
          <CheckinListModal
            bookingId={booking.id}
            checkinEnabled={!booking.isExpired && !booking.deletedAt}
            isOpen={isCheckinListModalVisible}
            onClose={handleToggleCheckinListModal}
          />
        )}

      {!!booking && (
        <PaymentConfirmationModal
          isOpen={isPaymentModalVisible}
          onClose={handleTogglePaymentModal}
          onConfirm={() => handlePayment(booking)}
        />
      )}

      {!!booking && (
        <BookingUpdateModal
          booking={booking}
          isOpen={isBookingUpdateModalVisible}
          onClose={() => {
            setIsBookingUpdateModalVisible((prevState) => !prevState);
          }}
          onConfirm={handleBooking}
        />
      )}

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteBooking}
        title="Confirmar cancelamento"
        message="Deseja realmente cancelar?"
        warningMessage={cancelBookingWarningMessage}
      />

      <Flex
        direction="column"
        w="100%"
        borderRadius={8}
        bg="white"
        p="8"
        {...props}
      >
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes da reserva
          </Heading>
        </Flex>

        {booking && (
          <Flex direction="column" mt="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 12, 8]}>
                <Flex direction="column" px="8">
                  <VStack flex="1" alignItems="flex-start" pl="8">
                    <Text fontSize="4xl" fontWeight="bold">
                      {booking.activitySchedule.activity.name}
                    </Text>

                    <HStack>
                      <Text>Local:</Text>
                      <Text color="gray.500">
                        {booking.activitySchedule.activity.spot.name}
                      </Text>
                    </HStack>

                    <HStack>
                      <Text>Data/horário da reserva:</Text>
                      <Text color="gray.500">{`${booking.formattedBookedDate} ${booking.formattedStartTime} - ${booking.formattedEndTime}`}</Text>
                    </HStack>

                    <HStack>
                      <Text>Nível reservado:</Text>
                      <Text color="gray.500">
                        {
                          UserExperience[
                            booking.activitySchedule.minUserExperience
                          ]
                        }
                      </Text>
                    </HStack>

                    <HStack>
                      <Text>Reservado em:</Text>
                      <Text color="gray.500">{booking.formattedCreatedAt}</Text>
                    </HStack>

                    {!!booking.bookingPrice && (
                      <>
                        <HStack>
                          <Text>Preço da reserva:</Text>
                          <Text color="gray.500">
                            {booking.formattedBookingPrice}
                          </Text>
                        </HStack>

                        {!!booking.payment && (
                          <>
                            <HStack>
                              <Text>Status do pagamento:</Text>
                              <Text>
                                {
                                  PrettyBookingPaymentStatus[
                                    booking.payment.status
                                  ]
                                }
                              </Text>
                            </HStack>

                            <HStack>
                              <Text>
                                Data da última alteração no pagamento:
                              </Text>
                              <Text>{booking.payment.formattedUpdatedAt}</Text>
                            </HStack>
                          </>
                        )}
                      </>
                    )}

                    {!!booking.formattedDeletedAt && (
                      <HStack>
                        <Text>Cancelado em:</Text>
                        <Text color="gray.500">
                          {booking.formattedDeletedAt}
                        </Text>
                      </HStack>
                    )}
                  </VStack>

                  <ButtonGroup mt="8" pl="8">
                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['ACTIVITY_FULL_ACCESS', 'ACTIVITY_READ_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <LinkButton
                        icon={<Icon as={RiEyeLine} fontSize="20" />}
                        to={{
                          pathname: '/activities/details',
                          state: {
                            activityId: booking.activitySchedule.activityId,
                          },
                        }}
                      >
                        Atividade
                      </LinkButton>
                    )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['SPOT_FULL_ACCESS', 'SPOT_READ_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <LinkButton
                        icon={<Icon as={RiEyeLine} fontSize="20" />}
                        to={{
                          pathname: '/spots/details',
                          state: {
                            spotId: booking.activitySchedule.activity.spotId,
                          },
                        }}
                      >
                        Local
                      </LinkButton>
                    )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['BOOKING_FULL_ACCESS', 'BOOKING_WRITE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) &&
                      !booking.isExpired && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          color="white"
                          colorScheme="yellow"
                          leftIcon={<Icon as={RiEditLine} fontSize="16" />}
                          onClick={() =>
                            setIsBookingUpdateModalVisible(
                              (prevState) => !prevState,
                            )
                          }
                        >
                          Editar
                        </Button>
                      )}

                    {!!booking.bookingPrice &&
                      !!booking.payment &&
                      ![
                        BookingPaymentStatus.PAYMENT_APPROVED,
                        BookingPaymentStatus.PAYMENT_EXTERNAL,
                      ].includes(booking.payment.status) &&
                      !booking.isExpired &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        [
                          'BOOKING_PAYMENT_FULL_ACCESS',
                          'BOOKING_PAYMENT_WRITE_ACCESS',
                        ].includes(feature.key),
                      ) && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="red"
                          onClick={handleTogglePaymentModal}
                          leftIcon={
                            <Icon as={RiMoneyDollarCircleLine} fontSize="20" />
                          }
                        >
                          Pagamento
                        </Button>
                      )}

                    {(!booking.payment ||
                      [
                        BookingPaymentStatus.PAYMENT_APPROVED,
                        BookingPaymentStatus.PAYMENT_EXTERNAL,
                      ].includes(booking.payment.status)) &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        [
                          'BOOKING_CHECKIN_FULL_ACCESS',
                          'BOOKING_CHECKIN_READ_ACCESS',
                          'BOOKING_CHECKIN_WRITE_ACCESS',
                        ].includes(feature.key),
                      ) && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="green"
                          onClick={handleToggleCheckinListModal}
                          leftIcon={
                            <Icon as={RiLoginCircleLine} fontSize="20" />
                          }
                        >
                          Checkins
                        </Button>
                      )}

                    {isCancelable &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        [
                          'BOOKING_FULL_ACCESS',
                          'BOOKING_DELETE_ACCESS',
                        ].includes(feature.key),
                      ) && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="red"
                          onClick={handleToggleDeleteConfirmationModal}
                          leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                        >
                          Cancelar
                        </Button>
                      )}
                  </ButtonGroup>
                </Flex>
              </GridItem>

              {booking.description && (
                <GridItem colSpan={[12, 12, 12, 12, 4]}>
                  <Flex height="100%">
                    <Divider
                      hidden={dividerOrientation === 'horizontal'}
                      px="2"
                      height="80%"
                      borderColor="gray.300"
                      orientation="vertical"
                    />

                    <Flex flexDirection="column" w="100%">
                      <Divider
                        hidden={dividerOrientation === 'vertical'}
                        borderColor="gray.300"
                        mt="4"
                        mb="8"
                      />
                      <Flex
                        flexDirection="column"
                        px={[8, 8, 8, 8, 0]}
                        w="100%"
                      >
                        <HStack mt={[0, 0, 0, 0, 2]}>
                          <Icon as={RiBookmarkLine} fontSize="20" />
                          <Text>Biografia</Text>
                        </HStack>

                        <Text
                          fontSize="sm"
                          color="gray.500"
                          mt="4"
                          px={[4, 4, 4, 4, 0]}
                        >
                          {booking.description}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </GridItem>
              )}
            </Grid>

            <Divider borderColor="gray.300" my="8" />

            <BookingDetailsUserInfo user={booking.user} />
          </Flex>
        )}
      </Flex>
    </>
  );
};
