const apiConfig = {
  baseURL: process.env.REACT_APP_API_URL ?? 'https://api.bookings.cjcd.com.br',
};

if (window.location.hostname.includes('sandbox')) {
  apiConfig.baseURL = 'https://api.bookings.sandbox.cjcd.com.br';
} else {
  apiConfig.baseURL = 'https://api.jhsf-bookings.com';
}

export default apiConfig.baseURL;
