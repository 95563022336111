import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  SimpleGrid,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { createUsersService } from '../../../../../services/Users/CreateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { translateError } from '../../../../../utils/errors';
import { validateCpf } from '../../../../../utils/validateCPF';
import {
  AsyncSelect,
  AsyncSelectOption,
} from '../../../../../components/Form/AsyncSelect';
import { listUsersService } from '../../../../../services/Users/ListUsersService';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { UserExperience } from '../../../../../models/users';
import { IAddressBase } from '../../../../../models/address';
import AddressForm, {
  addressValidation,
} from '../../../../../components/AddressForm';

type NewGuestFormData = {
  address?: IAddressBase;
  bio?: string;
  birthDate: Date;
  cpf?: string;
  email: string;
  experience: UserExperience;
  healthCheck?: Date;
  hostId: string;
  name: string;
  phone?: string;
};

interface ILocationState {
  groupKey?: 'GUEST' | 'DEPENDANT';
  hostId?: string;
}

const registerGuestFormSchema = Yup.object().shape({
  address: addressValidation
    .nullable()
    .transform((value, originalValue) =>
      !!originalValue &&
      Object.values<string>(originalValue).some((val) => !!val.length)
        ? originalValue
        : null,
    ),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  birthDate: Yup.date().nullable().required('Data de nascimento requerida'),
  cpf: Yup.string()
    .length(11, 'Valor inválido, requerido 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  experience: Yup.number()
    .integer()
    .oneOf([
      UserExperience['Nível 1 e 2'],
      UserExperience['Nível 3 e 4'],
      UserExperience['Nível 5'],
      UserExperience['Nível 6'],
      UserExperience['Nível 6 e 7'],
      UserExperience['Nível 7'],
      UserExperience['Nível 8'],
      UserExperience.Especial,
    ])
    .required('Experiência requerida.'),
  healthCheck: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  hostId: Yup.string()
    .uuid()
    .required('Requerido')
    .transform((value) => value.value),
  name: Yup.string().required('Nome requerido'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const GuestRegister = (): JSX.Element => {
  const { state } = useLocation<ILocationState | undefined>();

  const featureGroupKey = state?.groupKey || 'GUEST';

  const { goBack, push } = useHistory();

  const toast = useToast();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const { register, handleSubmit, formState, control, setValue } = useForm({
    resolver: yupResolver(registerGuestFormSchema),
  });

  const { errors } = formState;

  const experienceSelectOptions = useMemo(
    () => [
      {
        label: 'Nível 1 e 2',
        value: UserExperience['Nível 1 e 2'],
      },
      {
        label: 'Nível 3 e 4',
        value: UserExperience['Nível 3 e 4'],
      },
      {
        label: 'Nível 5',
        value: UserExperience['Nível 5'],
      },
      {
        label: 'Nível 6',
        value: UserExperience['Nível 6'],
      },
      {
        label: 'Nível 6 e 7',
        value: UserExperience['Nível 6 e 7'],
      },
      {
        label: 'Nível 7',
        value: UserExperience['Nível 7'],
      },
      {
        label: 'Nível 8',
        value: UserExperience['Nível 8'],
      },
      {
        label: 'Especial',
        value: UserExperience.Especial,
      },
    ],
    [],
  );

  const handleLoadHostSelectOption = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      const { items: hosts } = await listUsersService({
        name,
        featureGroups: ['MEMBER', 'DEPENDANT'],
        limit: 4,
      });

      const parsedUsersSelectOption: AsyncSelectOption[] = [
        ...hosts.map((host) => ({
          label: host.name,
          value: host.id,
        })),
      ];

      return parsedUsersSelectOption;
    },
    [],
  );

  useEffect(() => {
    if (state?.hostId) {
      setValue('hostId', { value: state.hostId });
    }
  }, [setValue, state?.hostId]);

  useEffect(() => {
    setValue('experience', UserExperience['Nível 1 e 2']);
  }, [setValue]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewGuest: SubmitHandler<NewGuestFormData> = useCallback(
    async ({
      address,
      bio,
      birthDate,
      cpf,
      email,
      experience,
      healthCheck,
      hostId,
      name,
      phone,
    }) => {
      try {
        const guest = await createUsersService({
          address,
          bio,
          birthDate,
          cpf,
          email,
          experience,
          featureGroupKey,
          healthCheck,
          hostId,
          name,
          phone,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateUserAvatarsService({
            avatarData: formData,
            userId: guest.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: `O ${
            featureGroupKey === 'GUEST' ? 'convidado' : 'dependente'
          } foi cadastrado corretamente.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        if (featureGroupKey === 'GUEST') {
          push('/guests/details', { guestId: guest.id });

          return;
        }

        push('/members/details', { memberId: guest.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              `Ocorreu um erro ao cadastrar o  ${
                featureGroupKey === 'GUEST' ? 'convidado' : 'dependente'
              }, tente novamente.`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, featureGroupKey, push, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewGuest)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar
          {featureGroupKey === 'GUEST' ? ' convidado' : ' dependente'}
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="6">
          <MaskedInput
            label="Nome completo"
            error={errors.name}
            {...register('name')}
          />

          <SimpleGrid minChildWidth="240px" spacing="6" w="full">
            <MaskedInput
              label="CPF"
              mask="cpf"
              error={errors.cpf}
              {...register('cpf')}
            />

            <DatePicker
              label="Data de nascimento"
              isClearable={false}
              maxDate={new Date()}
              control={control}
              error={errors.birthDate}
              {...register('birthDate')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="6" w="full">
            <InternationalPhoneInput
              flex={1}
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />

            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="6" w="full">
            <DatePicker
              label="Exame médico"
              maxDate={new Date()}
              control={control}
              error={errors.healthCheck}
              {...register('healthCheck')}
            />

            <ReactSelect
              label="Nível de experiência"
              name="experience"
              options={experienceSelectOptions}
              control={control}
              error={errors.experience}
            />
          </SimpleGrid>

          {!state?.hostId && (
            <AsyncSelect
              label="Responsável"
              name="hostId"
              loadOptions={handleLoadHostSelectOption}
              control={control}
              error={errors.hostId}
            />
          )}

          <MaskedInput
            label="Biografia"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Divider my="6" borderColor="gray.300" />

        <AddressForm errors={errors} register={register} />

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
