import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IDeleteSpotsProps {
  isVerified?: boolean;
  spotId: string;
}

const deleteSpotsService = async ({
  isVerified,
  spotId,
}: IDeleteSpotsProps): Promise<void> => {
  await jhsfBookingsApi.delete(`/spots/${spotId}`, { params: { isVerified } });
};

export default deleteSpotsService;
