import { IActivityBase, IActivityItemBase } from '../../models/activities';
import { ISpotBase, ISpotModalityBase } from '../../models/spots';
import { IVentureBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IDetailedActivitySpot extends ISpotBase {
  modalities: ISpotModalityBase[];
  venture: IVentureBase;
}

export interface IDetailedActivity extends IActivityBase {
  items: IActivityItemBase[];
  spot: IDetailedActivitySpot;
}

export const showActivitiesService = async (
  activityId: string,
): Promise<IDetailedActivity> => {
  const { data } = await jhsfBookingsApi.get<IDetailedActivity>(
    `/activities/${activityId}`,
  );

  return data;
};
