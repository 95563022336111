import {
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Icon,
  TableProps,
  ButtonGroup,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { isBefore } from 'date-fns';
import { useMemo, useState } from 'react';
import {
  RiDeleteBinLine,
  RiEyeLine,
  RiLoginCircleLine,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../../../../../../../../../components/ConfirmationModal';
import { LinkButton } from '../../../../../../../../../../../../../../../components/LinkButton';
import { PaymentConfirmationModal } from '../../../../../../../../../../../../../../../components/PaymentConfirmationModal';
import { useAuth } from '../../../../../../../../../../../../../../../hooks/auth';
import {
  IEventsScheduleBooking,
  IWeekdayActivityScheduleCheckin,
} from '../../../../../../../../../../../../../../../models/activities';
import { BookingPaymentStatus } from '../../../../../../../../../../../../../../../models/bookings';
import { UserExperience } from '../../../../../../../../../../../../../../../models/users';
import { IWeekdayActivitySchedule } from '../../../../../../../../../../../../../../../services/Activities/ListWeekdaysActivitySchedulesService';
import {
  createBookingCheckinsService,
  IBookingCheckin,
} from '../../../../../../../../../../../../../../../services/Bookings/CreateBookingCheckinsService';
import {
  createBookingExternalPaymentsService,
  IBookingExternalPayment,
} from '../../../../../../../../../../../../../../../services/Bookings/CreateBookingExternalPaymentsService';
import deleteBookingsService from '../../../../../../../../../../../../../../../services/Bookings/DeleteBookingsService';
import { translateError } from '../../../../../../../../../../../../../../../utils/errors';
import { maskDateTime } from '../../../../../../../../../../../../../../../utils/formatters/handleMask';

interface ISchedulebookingCheckin extends IWeekdayActivityScheduleCheckin {
  formattedCreatedAt: string;
}

export interface IScheduleBooking extends IEventsScheduleBooking {
  bookedDateTime: Date;
  experience: UserExperience;
  checkins: ISchedulebookingCheckin[];
  isCancelable: boolean;
}

export interface ICreateCheckin {
  booking: IScheduleBooking;
  checkin: IBookingCheckin;
}

interface IScheduleBookingsTableProps extends TableProps {
  schedules: IWeekdayActivitySchedule[];
  onCreateCheckin: (data: ICreateCheckin) => void;
  onDeleteBooking: (booking: IScheduleBooking) => void;
  onPayBooking: (booking: IBookingExternalPayment) => void;
}

export const ScheduleBookingsTable = ({
  schedules,
  onCreateCheckin,
  onDeleteBooking,
  onPayBooking,
  ...rest
}: IScheduleBookingsTableProps): JSX.Element => {
  const toast = useToast();
  const { user: authenticatedUser } = useAuth();

  const [handlingBooking, setHandlingBooking] = useState<IScheduleBooking>();

  const scheduleBookings = useMemo(
    () =>
      schedules.reduce(
        (bookings, schedule) => [
          ...bookings,
          ...schedule.bookings.map((booking) => {
            const bookedDateTime = new Date(
              booking?.bookedDate.concat(`T${schedule.startTime}`) || '',
            );

            const isCancelable =
              !booking?.checkins.length &&
              isBefore(new Date(), bookedDateTime) &&
              !booking?.deletedAt;

            return {
              ...booking,
              bookedDateTime,
              checkins: booking.checkins.map((checkin) => ({
                ...checkin,
                formattedCreatedAt: maskDateTime(checkin.createdAt),
              })),
              experience: schedule.experience,
              isCancelable,
            };
          }),
        ],
        [] as IScheduleBooking[],
      ),
    [schedules],
  );

  const cancelBookingWarningMessage = useMemo(() => {
    if (!handlingBooking) {
      return undefined;
    }

    if (
      handlingBooking?.payment?.status === BookingPaymentStatus.PAYMENT_EXTERNAL
    ) {
      return 'Essa reserva foi paga externamente, o reembolso também deverá ser feito externamente.';
    }

    return undefined;
  }, [handlingBooking]);

  const [
    isCheckinConfirmationModalVisible,
    setIsCheckinConfirmationModalVisible,
  ] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);

  const handleTooggleCheckinConfirmationModal = (): void => {
    setIsCheckinConfirmationModalVisible((prevState) => !prevState);
  };

  const handleToggleDeleteConfirmationModal = (): void => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  };

  const handleTogglePaymentModal = (): void => {
    setIsPaymentModalVisible((prevState) => !prevState);
  };

  const onPay = async (bookingId: string): Promise<void> => {
    try {
      const paydBooking = await createBookingExternalPaymentsService(bookingId);

      onPayBooking(paydBooking);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao efetuar pagamento',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao efetuar pagamento da reserva, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  };

  const handleCancelBookingDelete = (): void => {
    setHandlingBooking(undefined);

    handleToggleDeleteConfirmationModal();
  };

  const handleDeleteConfirmation = (booking: IScheduleBooking): void => {
    setHandlingBooking(booking);

    handleToggleDeleteConfirmationModal();
  };

  const handlePayment = (booking: IScheduleBooking): void => {
    setHandlingBooking(booking);

    handleTogglePaymentModal();
  };

  const handleCheckin = (booking: IScheduleBooking): void => {
    setHandlingBooking(booking);

    handleTooggleCheckinConfirmationModal();
  };

  const handleCreateCheckin = async (): Promise<void> => {
    if (handlingBooking) {
      try {
        const checkin = await createBookingCheckinsService(handlingBooking.id);

        handleTooggleCheckinConfirmationModal();

        onCreateCheckin({ booking: handlingBooking, checkin });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao cadastrar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar o checkin, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  };

  const deleteBooking = async (booking: IScheduleBooking): Promise<void> => {
    try {
      await deleteBookingsService(booking.id);

      onDeleteBooking(booking);

      setHandlingBooking(undefined);

      toast({
        title: 'Cancelada com sucesso',
        description: 'A reserva foi cancelada corretamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao cancelar',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao cancelar a reserva, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  };

  return (
    <>
      {handlingBooking && (
        <ConfirmationModal
          isOpen={isCheckinConfirmationModalVisible}
          onClose={handleTooggleCheckinConfirmationModal}
          onConfirm={handleCreateCheckin}
          title="Confirmar checkin"
          message="Deseja realmente criar um checkin para essa reserva?"
        />
      )}

      {!!handlingBooking && (
        <PaymentConfirmationModal
          isOpen={isPaymentModalVisible}
          onClose={() => {
            setHandlingBooking(undefined);
            handleTogglePaymentModal();
          }}
          onConfirm={() => {
            onPay(handlingBooking.id);
            setHandlingBooking(undefined);
            handleTogglePaymentModal();
          }}
        />
      )}

      {!!handlingBooking && (
        <ConfirmationModal
          isOpen={!!isDeleteConfirmationModalVisible}
          onClose={handleCancelBookingDelete}
          onConfirm={() => deleteBooking(handlingBooking)}
          title="Confirmar cancelamento"
          message="Deseja realmente cancelar?"
          warningMessage={cancelBookingWarningMessage}
        />
      )}

      <Table colorScheme="blue" {...rest}>
        <Thead>
          <Tr>
            <Th>Nome</Th>

            <Th>Nível reservado</Th>

            <Th>Checkins</Th>

            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {scheduleBookings.map((scheduleBooking) => (
            <Tr key={scheduleBooking.id}>
              <Td>
                <Text fontWeight="bold">{scheduleBooking.user.name}</Text>
              </Td>

              <Td>{UserExperience[scheduleBooking.experience]}</Td>

              <Td>
                {scheduleBooking.checkins.map((checkin) => (
                  <Text fontSize="sm">{`${checkin.user.name} - ${checkin.formattedCreatedAt}`}</Text>
                ))}
              </Td>

              <Td textAlign="right">
                <ButtonGroup>
                  {scheduleBooking.payment &&
                    ![
                      BookingPaymentStatus.PAYMENT_APPROVED,
                      BookingPaymentStatus.PAYMENT_EXTERNAL,
                    ].includes(scheduleBooking.payment.status) &&
                    !scheduleBooking.isExpired && (
                      <IconButton
                        aria-label="Payment"
                        size="sm"
                        icon={
                          <Icon as={RiMoneyDollarCircleLine} fontSize="18" />
                        }
                        fontSize="16"
                        colorScheme="red"
                        onClick={() => handlePayment(scheduleBooking)}
                      />
                    )}

                  {(!scheduleBooking.payment ||
                    [
                      BookingPaymentStatus.PAYMENT_APPROVED,
                      BookingPaymentStatus.PAYMENT_EXTERNAL,
                    ].includes(scheduleBooking.payment.status)) &&
                    !scheduleBooking.isExpired &&
                    !scheduleBooking.deletedAt && (
                      <IconButton
                        aria-label="Checkins"
                        size="sm"
                        icon={<Icon as={RiLoginCircleLine} fontSize="16" />}
                        fontSize="16"
                        colorScheme="green"
                        onClick={() => handleCheckin(scheduleBooking)}
                      />
                    )}

                  <LinkButton
                    px={0}
                    to={{
                      pathname: '/bookings/details',
                      state: {
                        bookingId: scheduleBooking.id,
                      },
                    }}
                  >
                    <Icon as={RiEyeLine} fontSize="16" />
                  </LinkButton>

                  {scheduleBooking.isCancelable &&
                    authenticatedUser.featureGroup.features.some((feature) =>
                      ['BOOKING_FULL_ACCESS', 'BOOKING_DELETE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <IconButton
                        aria-label="delete-booking"
                        size="sm"
                        icon={<Icon as={RiDeleteBinLine} fontSize="16" />}
                        colorScheme="red"
                        onClick={() =>
                          handleDeleteConfirmation(scheduleBooking)
                        }
                      />
                    )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};
