import React, { createContext, useCallback, useState, useContext } from 'react';
import { IActivityScheduleBase } from '../models/activities';
import { ISpotModalityBase } from '../models/spots';
import {
  IDetailedBookingQueue,
  IDetailedBookingQueueActivityScheduleItem,
} from '../services/BookingQueues/ShowBookingQueuesService';
import { maskDate, maskMoney } from '../utils/formatters/handleMask';

interface IBookingQueueActivitySchedule extends IActivityScheduleBase {
  formattedEndTime: string;
  formattedStartTime: string;
  modality?: ISpotModalityBase;
}

interface IBookingQueueContextScheduleItem
  extends IDetailedBookingQueueActivityScheduleItem {
  formattedPrice: string;
}

export interface IBookingQueueContextState extends IDetailedBookingQueue {
  activitySchedule?: IBookingQueueActivitySchedule;
  formattedBookedDate: string;
  formattedCreatedAt: string;
  scheduleItems: IBookingQueueContextScheduleItem[];
}

interface IBookingQueueContextData {
  bookingQueue?: IBookingQueueContextState;
  handleBookingQueue: (bookingQueue?: IDetailedBookingQueue) => void;
}

const BookingQueueContext = createContext<IBookingQueueContextData>(
  {} as IBookingQueueContextData,
);

const BookingQueueProvider: React.FC = ({ children }) => {
  const [bookingQueue, setBookingQueue] = useState<IBookingQueueContextState>();

  const handleBookingQueue = useCallback(
    (bookingQueueData?: IDetailedBookingQueue) => {
      if (bookingQueueData) {
        const [bookedYear, bookedMonth, bookedDay] =
          bookingQueueData.bookedDate.split('-');

        setBookingQueue({
          ...bookingQueueData,
          formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
          formattedCreatedAt: maskDate(bookingQueueData.createdAt),
          activitySchedule: bookingQueueData.activitySchedule
            ? {
                ...bookingQueueData.activitySchedule,
                formattedEndTime:
                  bookingQueueData.activitySchedule.endTime.slice(0, 5),
                formattedStartTime:
                  bookingQueueData.activitySchedule.startTime.slice(0, 5),
              }
            : undefined,
          scheduleItems: bookingQueueData.scheduleItems.map((scheduleItem) => ({
            ...scheduleItem,
            formattedPrice: scheduleItem.price
              ? maskMoney(scheduleItem.price)
              : '-',
          })),
        });
      } else {
        setBookingQueue(undefined);
      }
    },
    [],
  );

  return (
    <BookingQueueContext.Provider
      value={{
        bookingQueue,
        handleBookingQueue,
      }}
    >
      {children}
    </BookingQueueContext.Provider>
  );
};

const useBookingQueue = (): IBookingQueueContextData =>
  useContext(BookingQueueContext);

export { BookingQueueProvider, useBookingQueue };
