import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IDeleteActivitySchedulesServiceProps {
  activityScheduleId: string;
  isVerified?: boolean;
}

const deleteActivitySchedulesService = async ({
  activityScheduleId,
  isVerified,
}: IDeleteActivitySchedulesServiceProps): Promise<void> => {
  await jhsfBookingsApi.delete(`/activities/schedules/${activityScheduleId}`, {
    params: { isVerified },
  });
};

export default deleteActivitySchedulesService;
