import { IAddressBase } from '../../models/address';
import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import { IUserBase, UserGender } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateUserProps {
  address?: IAddressBase;
  userId: string;
  bio?: string;
  birthDate?: Date;
  categoryId?: string;
  cpf?: string;
  cnpj?: string;
  companyName?: string;
  email: string;
  experience?: number;
  gender?: UserGender;
  featureGroupId?: string;
  healthCheck?: Date;
  hostId?: string;
  name: string;
  onboardingPending?: boolean;
  phone?: string;
  tradeName?: string;
  ventureId?: string | null;
}

export interface IUpdateUserDetailedFeatureGroup extends IFeatureGroupBase {
  features: IFeatureBase[];
}

export interface IUpdatedUser extends IUserBase {
  featureGroup: IUpdateUserDetailedFeatureGroup;
}

export const updateUsersService = async ({
  address,
  userId,
  bio,
  birthDate,
  categoryId,
  cpf,
  cnpj,
  companyName,
  email,
  experience,
  gender,
  featureGroupId,
  healthCheck,
  hostId,
  name,
  onboardingPending,
  phone,
  tradeName,
  ventureId,
}: IUpdateUserProps): Promise<IUpdatedUser> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedUser>(`/users/${userId}`, {
    address,
    bio,
    birthDate,
    categoryId,
    cpf,
    cnpj,
    companyName,
    email,
    experience,
    gender,
    featureGroupId,
    healthCheck,
    hostId,
    name,
    onboardingPending,
    phone,
    tradeName,
    ventureId,
  });

  return data;
};
