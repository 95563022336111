import { IEntityBase } from './entityBase';

export enum BookingPaymentStatus {
  REGISTERING = 'REGISTERING',
  REGISTER_FAILED = 'REGISTER_FAILED',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAYMENT_CANCELED = 'PAYMENT_CANCELED',
  PAYMENT_EXTERNAL = 'PAYMENT_EXTERNAL',
}

export enum PrettyBookingPaymentStatus {
  REGISTERING = 'Registrando pagamento',
  REGISTER_FAILED = 'Falha no registro do pagamento',
  PAYMENT_PENDING = 'Pagamento pendente',
  PAYMENT_PROCESSING = 'Processando pagamento',
  PAYMENT_APPROVED = 'Pagamento aprovado',
  PAYMENT_REJECTED = 'Pagamento rejeitado',
  PAYMENT_CANCELED = 'Pagamento cancelado',
  PAYMENT_EXTERNAL = 'Pagamento externo',
}

export interface IBookingPaymentBase extends IEntityBase {
  orderRef: string;
  orderId: string;
  status: BookingPaymentStatus;
  bookingId: string;
  userId: string;
}

export interface IBookingCheckinBase extends IEntityBase {
  bookingId: string;
  userId: string;
}

export interface IBookingItemBase extends IEntityBase {
  itemId: string;
  description?: string;
  name: string;
  bookingId: string;
}

export interface IBookingScheduleItemBase extends IEntityBase {
  scheduleItemId: string;
  description?: string;
  name: string;
  price?: number | null;
  totalAmount: number;
  bookingId: string;
  userId?: string | null;
}

export interface IBookingBase extends IEntityBase {
  activityScheduleId: string;
  bookedDate: string;
  bookingPrice?: number;
  description?: string;
  endTime?: string;
  isActive: boolean;
  isExpired?: boolean;
  startTime?: string;
  titularUserId: string;
  userId: string;
  deletedAt?: string;
}
