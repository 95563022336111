import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Text,
  Box,
  Divider,
  HStack,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { useCalendarEvents } from '../../../../../../../../../../../../../hooks/calendarEvent';
import { WaveSideEnum } from '../../../../../../../../../../../../../models/activities';
import { BookingPaymentStatus } from '../../../../../../../../../../../../../models/bookings';
import { UserExperience } from '../../../../../../../../../../../../../models/users';
import { IWeekdayActivitySchedule } from '../../../../../../../../../../../../../services/Activities/ListWeekdaysActivitySchedulesService';
import { IBookingExternalPayment } from '../../../../../../../../../../../../../services/Bookings/CreateBookingExternalPaymentsService';
import {
  ICreateCheckin,
  IScheduleBooking,
  ScheduleBookingsTable,
} from './components/ScheduleBookingsTable';

const formatDay = (date: Date): string =>
  date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
  });

const formatWeekDay = (date: Date): string =>
  date.toLocaleDateString('pt-BR', {
    weekday: 'long',
  });

const formatTime = (date: Date): string =>
  date.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
  });
type ScheduleBookingsModalProps = {
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
  onCreateBooking: (schedule: IWeekdayActivitySchedule) => void;
};

export default function ScheduleBookingsModal({
  eventId,
  isOpen,
  onClose,
  onCreateBooking,
}: ScheduleBookingsModalProps): JSX.Element {
  const { calendarEvents, handleCalendarEvents } = useCalendarEvents();

  const event = useMemo(
    () => calendarEvents.find((ev) => ev.id === eventId),
    [calendarEvents, eventId],
  );

  if (!event) {
    onClose();

    return <></>;
  }

  const handleCreateCheckin = ({ booking, checkin }: ICreateCheckin): void => {
    handleCalendarEvents(
      calendarEvents.map((calendarEvent) =>
        calendarEvent.id === event.id
          ? {
              ...calendarEvent,
              schedules: calendarEvent.schedules.map((eventSchedule) =>
                eventSchedule.activityScheduleId === booking.activityScheduleId
                  ? {
                      ...eventSchedule,
                      bookings: eventSchedule.bookings.map((scheduleBooking) =>
                        scheduleBooking.id === checkin.bookingId
                          ? {
                              ...scheduleBooking,
                              checkins: [checkin, ...scheduleBooking.checkins],
                            }
                          : scheduleBooking,
                      ),
                    }
                  : eventSchedule,
              ),
            }
          : calendarEvent,
      ),
    );
  };

  const handleDeleteBooking = (booking: IScheduleBooking): void => {
    handleCalendarEvents(
      calendarEvents.map((calendarEvent) => {
        const schedules = calendarEvent.schedules.map((eventSchedule) => {
          const bookings = eventSchedule.bookings.filter(
            (scheduleBooking) => scheduleBooking.id !== booking.id,
          );
          if (eventSchedule.activityScheduleId === booking.activityScheduleId) {
            return {
              ...eventSchedule,
              booked: bookings.length,
              bookings,
            };
          }

          return eventSchedule;
        });

        const eventVacancies = schedules.reduce(
          (vacancies, e) => vacancies + e.vacancies - e.booked,
          0,
        );

        if (calendarEvent.id === event.id) {
          return {
            ...calendarEvent,
            title: eventVacancies > 0 ? `${eventVacancies} vaga(s)` : 'Lotado',
            isFull: eventVacancies <= 0,
            schedules,
          };
        }

        return calendarEvent;
      }),
    );
  };

  const handlePayBooking = (booking: IBookingExternalPayment): void => {
    handleCalendarEvents(
      calendarEvents.map((calendarEvent) =>
        calendarEvent.id === event.id
          ? {
              ...calendarEvent,
              schedules: calendarEvent.schedules.map((eventSchedule) =>
                eventSchedule.activityScheduleId === booking.activityScheduleId
                  ? {
                      ...eventSchedule,
                      bookings: eventSchedule.bookings.map((scheduleBooking) =>
                        scheduleBooking.id === booking.id
                          ? {
                              ...scheduleBooking,
                              payment: scheduleBooking.payment
                                ? {
                                    ...scheduleBooking.payment,
                                    status:
                                      BookingPaymentStatus.PAYMENT_EXTERNAL,
                                  }
                                : undefined,
                            }
                          : scheduleBooking,
                      ),
                    }
                  : eventSchedule,
              ),
            }
          : calendarEvent,
      ),
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />

        <ModalContent maxH="88vh">
          <ModalHeader>
            {`Reservas dia: ${formatDay(event.start)} - ${formatWeekDay(
              event.start,
            )} | ${formatTime(event.start)} - ${formatTime(event.end)}`}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody overflow="auto">
            <SimpleGrid columns={3} spacing="2" w="100%">
              {event.schedules.map((schedule) => (
                <Box
                  key={schedule.activityScheduleId}
                  borderWidth={1}
                  borderRadius="8"
                  p="2"
                >
                  <HStack justify="space-between">
                    <Text as="strong">
                      {UserExperience[schedule.experience]}
                    </Text>

                    {!schedule.isExpired && (
                      <IconButton
                        aria-label="create-booking"
                        size="xs"
                        icon={<Icon as={RiAddLine} fontSize="18" />}
                        fontSize="16"
                        colorScheme="green"
                        onClick={() => onCreateBooking(schedule)}
                      />
                    )}
                  </HStack>

                  <Box>
                    {schedule.modalityTitle && (
                      <p>
                        <Text as="span">Modalidade: </Text>
                        <Text as="span">{schedule.modalityTitle}</Text>
                      </p>
                    )}

                    {!!schedule.waveSide && (
                      <p>
                        <Text as="span">Onda: </Text>
                        <Text as="span">{WaveSideEnum[schedule.waveSide]}</Text>
                      </p>
                    )}

                    <Text>
                      {`${schedule.booked} reservas -  ${
                        schedule.vacancies - schedule.booked
                      } vagas`}
                    </Text>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>

            {!!event.schedules.some(
              (schedule) => !!schedule.bookings.length,
            ) && (
              <>
                <Divider my="6" borderColor="gray.300" />

                <ScheduleBookingsTable
                  schedules={event.schedules}
                  onCreateCheckin={handleCreateCheckin}
                  onDeleteBooking={handleDeleteBooking}
                  onPayBooking={handlePayBooking}
                />
              </>
            )}
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}
