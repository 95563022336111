import { useHistory } from 'react-router-dom';
import {
  Heading,
  Flex,
  Button,
  Icon,
  Link as ChakraLink,
  Text,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
  Divider,
  useBreakpointValue,
  FlexProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  RiBookmarkLine,
  RiDeleteBinLine,
  RiEditLine,
  RiMailSendLine,
  RiWhatsappLine,
} from 'react-icons/ri';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { LinkButton } from '../../../../../../../components/LinkButton';
import deleteUsersService from '../../../../../../../services/Users/DeleteUsersService';
import { translateError } from '../../../../../../../utils/errors';
import createProfileConfirmationsService from '../../../../../../../services/Users/CreateProfileConfirmationsService';
import { useUser } from '../../../../../../../hooks/user';
import { useAuth } from '../../../../../../../hooks/auth';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import AddressDetails from '../../../../../../../components/AddressDetails';

export const UserDetailsInfo = (props: FlexProps): JSX.Element => {
  const toast = useToast();

  const { push } = useHistory();

  const { user: authenticatedUser } = useAuth();

  const { user } = useUser();

  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    xl: 'vertical',
  });

  const [
    isSendProfileConfirmationModalVisible,
    setIsSendProfileConfirmationModalVisible,
  ] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const handleToggleProfileConfirmationModal = useCallback(() => {
    setIsSendProfileConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleProfileConfirmation = useCallback(async () => {
    try {
      if (user) {
        await createProfileConfirmationsService(user.id);
      }

      toast({
        title: 'Notificado com sucesso',
        description: 'O funcionário foi notificado corretamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      handleToggleProfileConfirmationModal();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao notificar',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao enviar a notificação, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [user, toast, handleToggleProfileConfirmationModal]);

  const handleDeleteUser = useCallback(async () => {
    if (user?.id) {
      try {
        await deleteUsersService(user.id);

        toast({
          title: 'Excluído com sucesso',
          description: 'O funcionário foi excluído corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/users');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao excluir',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao excluir o funcionário, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [user?.id, toast, push]);

  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteUser}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      <ConfirmationModal
        isOpen={isSendProfileConfirmationModalVisible}
        onClose={handleToggleProfileConfirmationModal}
        onConfirm={handleProfileConfirmation}
        title="Enviar notificação"
        message="Deseja reenviar a notificação?"
      />

      <Flex
        direction="column"
        w="100%"
        borderRadius={8}
        bg="white"
        p="8"
        {...props}
      >
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do funcionário
          </Heading>
        </Flex>

        {user && (
          <Flex direction="column" mt="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 12, 8]}>
                <Flex direction="column" px="8" height="100%">
                  <Flex alignItems="flex-start" height="100%">
                    <Avatar size="2xl" name={user.name} src={user.avatarUrl} />

                    <VStack flex="1" alignItems="flex-start" ml="8">
                      <Text fontSize="4xl" fontWeight="bold">
                        {user.name}
                      </Text>

                      <HStack>
                        <Text>Grupo de permissões:</Text>
                        <Text mt="2" color="gray.500">
                          {user.featureGroup?.name || '-'}
                        </Text>
                      </HStack>

                      <HStack>
                        <Text>Telefone:</Text>
                        {user.phone && (
                          <ChakraLink
                            href={`https://wa.me/${user.phone?.replace(
                              /\D/g,
                              '',
                            )}`}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={RiWhatsappLine} mx="2" fontSize="24" />
                            <Text as="span" color="blue.500">
                              {user.phone}
                            </Text>
                          </ChakraLink>
                        )}
                      </HStack>

                      <HStack>
                        <Text>E-mail:</Text>
                        <Text color="gray.500">{user.email}</Text>
                      </HStack>

                      {user.featureGroup?.key === 'SELLER' ? (
                        <HStack>
                          <Text>CNPJ:</Text>
                          <Text color="gray.500">{user.cnpj}</Text>
                        </HStack>
                      ) : (
                        <HStack>
                          <Text>CPF:</Text>
                          <Text color="gray.500">{user.cpf}</Text>
                        </HStack>
                      )}

                      <HStack>
                        <Text>Nome da empresa:</Text>
                        <Text color="gray.500">{user.companyName}</Text>
                      </HStack>

                      <HStack>
                        <Text>Nome fantasia:</Text>
                        <Text color="gray.500">{user.tradeName}</Text>
                      </HStack>

                      {!!user.venture && (
                        <HStack>
                          <Text>Empreendimento:</Text>
                          <Text color="gray.500">{user.venture.name}</Text>
                        </HStack>
                      )}

                      <HStack>
                        <Text>Data de cadastro:</Text>
                        <Text color="gray.500">{user.formattedCreatedAt}</Text>
                      </HStack>
                    </VStack>
                  </Flex>

                  <ButtonGroup mt="8">
                    {user?.confirmPending &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        [
                          'USER_FULL_ACCESS',
                          'USER_PROFILE_CONFIRMATION_WRITE_ACCESS',
                        ].includes(feature.key),
                      ) && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="blue"
                          onClick={handleToggleProfileConfirmationModal}
                          leftIcon={<Icon as={RiMailSendLine} fontSize="20" />}
                        >
                          Reenviar confirmação
                        </Button>
                      )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['USER_FULL_ACCESS', 'USER_WRITE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <LinkButton
                        colorScheme="yellow"
                        color="white"
                        icon={<Icon as={RiEditLine} fontSize="16" />}
                        to={{
                          pathname: '/users/update',
                          state: {
                            userId: user.id,
                          },
                        }}
                      >
                        Editar
                      </LinkButton>
                    )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['USER_FULL_ACCESS', 'USER_DELETE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleToggleDeleteConfirmationModal}
                        leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                      >
                        Excluir
                      </Button>
                    )}
                  </ButtonGroup>
                </Flex>
              </GridItem>

              <GridItem colSpan={[12, 12, 12, 12, 4]}>
                <Flex height="100%">
                  <Divider
                    hidden={dividerOrientation === 'horizontal'}
                    my="auto"
                    px="2"
                    height="100%"
                    borderColor="gray.300"
                    orientation="vertical"
                  />

                  <Flex flexDirection="column" w="100%">
                    <Divider
                      hidden={dividerOrientation === 'vertical'}
                      borderColor="gray.300"
                      mt="4"
                      mb="8"
                    />

                    <Flex flexDirection="column" px={[8, 8, 8, 8, 0]} w="100%">
                      {user.address && (
                        <AddressDetails
                          mt={[0, 0, 0, 0, 2]}
                          address={user.address}
                        />
                      )}

                      {user.bio && (
                        <>
                          <HStack mt="4">
                            <Icon as={RiBookmarkLine} fontSize="20" />
                            <Text fontSize="lg">Biografia</Text>
                          </HStack>

                          <Text
                            fontSize="sm"
                            color="gray.500"
                            mt="4"
                            px={[4, 4, 4, 4, 0]}
                          >
                            {user.bio}
                          </Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </GridItem>
            </Grid>
          </Flex>
        )}
      </Flex>
    </>
  );
};
