const AppErrorMessages = [
  {
    message: 'activity-schedule-conflict',
    ptBr: 'Já existe evento nesse horário para a mesma modalidade e nível de experiência.',
  },
  {
    message: 'activity-schedule-item-quantity-unavailable',
    ptBr: 'Não há mais vagas disponíveis para algum dos opcionais selecionados.',
  },
  {
    message: 'activity-not-found',
    ptBr: 'Atividade não encontrada.',
  },
  {
    message: 'cnpj-already-in-use',
    ptBr: 'CNPJ já está em uso.',
  },
  {
    message: 'email-already-in-use',
    ptBr: 'E-mail já está em uso.',
  },
  {
    message: 'insufficient-privilege',
    ptBr: 'Não autorizado.',
  },
  {
    message: 'schedule-already-booked',
    ptBr: 'Já existe reserva ativa para essa atividade.',
  },
  {
    message: 'spot-already-booked',
    ptBr: 'Cliente já existe reserva ativa para esse local.',
  },
  {
    message: 'venture-not-found',
    ptBr: 'Empreendimento não encontrado.',
  },
  {
    message: 'venture-ref-already-in-use',
    ptBr: 'Ref já está em uso.',
  },
  {
    message: 'cpf-already-in-use',
    ptBr: 'CPF já está em uso.',
  },
  {
    message: 'feature-key-already-registered',
    ptBr: 'Chave já está em uso.',
  },
  {
    message: 'feature-name-already-registered',
    ptBr: 'Nome já está em uso.',
  },
  {
    message: 'user-not-found',
    ptBr: 'Funcionário não encontrado.',
  },
  {
    message: 'user-max-day-bookings',
    ptBr: 'Limite de reservas diárias atingido.',
  },
  {
    message: 'user-guest-max-day-bookings',
    ptBr: 'Limite de reservas diárias de convidados atingido.',
  },
  {
    message: 'user-category-has-users-assigned',
    ptBr: 'Existem membros vinculados à essa categoria.',
  },
  {
    message: 'host-blocked',
    ptBr: 'Responsável bloqueado.',
  },
  {
    message: 'titular-blocked',
    ptBr: 'Titular bloqueado.',
  },
  {
    message: 'user-blocked',
    ptBr: 'Usuário bloqueado.',
  },
  {
    message: 'past-start-time',
    ptBr: 'Hora inicial passada.',
  },
  {
    message: 'overlapping-time-range',
    ptBr: 'Intervalo de horário sobrepondo outra reserva.',
  },
  {
    message: 'booking-no-vacancies-available',
    ptBr: 'Não há vagas disponíveis.',
  },
  {
    message: 'booking-time-range-invalid',
    ptBr: 'Intervalo de horário fora dos limites do evento.',
  },
  {
    message: 'invalid-amount',
    ptBr: 'Valor inválido, confira o saldo.',
  },
  {
    message: 'user-birth-date-missing',
    ptBr: 'Usuário sem data de nascimento cadastrado.',
  },
  {
    message: 'user-cpf-missing',
    ptBr: 'Usuário sem CPF cadastrado.',
  },
  {
    message: 'user-phone-missing',
    ptBr: 'Usuário sem telefone cadastrado.',
  },
];

interface ITRanslateErrorProps {
  message: string;
  language?: 'ptBr';
}

export const translateError = ({
  message,
  language = 'ptBr',
}: ITRanslateErrorProps): string | undefined =>
  AppErrorMessages.find((errorMessage) => errorMessage.message === message)?.[
    language
  ];
