import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import {
  IActivityScheduleBase,
  IActivityScheduleBlockBase,
  WaveSideEnum,
} from '../../models/activities';
import { IBookingBase } from '../../models/bookings';

interface ICreateActivityScheduleBlocks {
  blockedDate: string;
}

interface ICreateActivityScheduleProps {
  activityId: string;
  allowLinkBookings?: boolean;
  allowPartialTimeBookings?: boolean;
  bookingPrice?: number;
  daysOfWeek: Array<number>;
  endDate?: string;
  endTime: string;
  isActive: boolean;
  minUserExperience: number;
  modalityId?: string | null;
  scheduleBlocks: ICreateActivityScheduleBlocks[];
  startDate: string;
  startTime: string;
  totalVacancies: number;
  waveSide?: WaveSideEnum;
}

export interface INewActivitySchedule extends IActivityScheduleBase {
  bookings: IBookingBase[];
  scheduleBlocks: IActivityScheduleBlockBase[];
}

export const createActivitySchedulesService = async ({
  activityId,
  allowLinkBookings,
  allowPartialTimeBookings,
  bookingPrice,
  daysOfWeek,
  endDate,
  endTime,
  isActive,
  minUserExperience,
  modalityId,
  scheduleBlocks,
  startDate,
  startTime,
  totalVacancies,
  waveSide,
}: ICreateActivityScheduleProps): Promise<INewActivitySchedule> => {
  const { data } = await jhsfBookingsApi.post<INewActivitySchedule>(
    `/activities/${activityId}/schedules`,
    {
      allowLinkBookings,
      allowPartialTimeBookings,
      bookingPrice,
      daysOfWeek,
      endDate,
      endTime,
      isActive,
      minUserExperience,
      modalityId,
      scheduleBlocks,
      startDate,
      startTime,
      totalVacancies,
      waveSide,
    },
  );

  return data;
};
