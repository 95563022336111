import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
  VStack,
  useToast,
  HStack,
  Text,
  Box,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from 'react-router';
import moment from 'moment';
import { isSameDay } from 'date-fns';
import { ConfirmationModal } from '../../../../../../../../../../../components/ConfirmationModal';
import {
  AsyncSelectOption,
  AsyncSelect,
} from '../../../../../../../../../../../components/Form/AsyncSelect';
import { DatePicker } from '../../../../../../../../../../../components/Form/DatePicker';
import { MaskedInput } from '../../../../../../../../../../../components/Form/MaskedInput';
import { ReactMultiSelect } from '../../../../../../../../../../../components/Form/ReactMultiSelect';
import { SelectOption } from '../../../../../../../../../../../components/Form/ReactSelect';
import { useActivity } from '../../../../../../../../../../../hooks/activity';
import {
  IActivityItemBase,
  IActivityScheduleItemBase,
} from '../../../../../../../../../../../models/activities';
import {
  ICreateBookingQueueProps,
  createBookingQueuesService,
} from '../../../../../../../../../../../services/BookingQueues/CreateBookingQueuesService';
import { createBookingsService } from '../../../../../../../../../../../services/Bookings/CreateBookingsService';
import { listUsersService } from '../../../../../../../../../../../services/Users/ListUsersService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { listWeekdaysActivitiesSchedulesService } from '../../../../../../../../../../../services/Activities/ListWeekdaysActivitySchedulesService';
import { ISpotModalityBase } from '../../../../../../../../../../../models/spots';
import { UserExperience } from '../../../../../../../../../../../models/users';
import { listActivityScheduleItemsService } from '../../../../../../../../../../../services/Activities/ListActivityScheduleItemsService';
import { maskMoney } from '../../../../../../../../../../../utils/formatters/handleMask';

type NewBookingFormData = {
  bookedDate: Date;
  description?: string;
  endTime?: string;
  itemsId?: string[];
  startTime?: string;
  scheduleItemsId?: string[];
  userId: string;
};

interface IBookingQueueData {
  errorMessage:
    | 'booking-no-vacancies-available'
    | 'user-max-day-bookings'
    | 'user-guest-max-day-bookings';
  queueData: ICreateBookingQueueProps;
}

export interface INewBookingData extends NewBookingFormData {
  items?: IActivityItemBase[];
}

enum ErrorMessage {
  'booking-no-vacancies-available' = 'Não há vagas disponíveis, deseja adicionar uma reseva na fila de espera?',
  'user-max-day-bookings' = 'Limite de reservas atingido, deseja adicionar uma reserva na fila de espera?',
  'user-guest-max-day-bookings' = 'Limite de reservas de convidados atingido, deseja adicionar uma reserva na fila de espera?',
}

interface IBookingRegisterModalScheduleItem extends IActivityScheduleItemBase {
  formattedPrice?: string;
}

interface IBookingRegisterModalProps {
  activityScheduleId: string;
  allowPartialTimeBookings?: boolean;
  defaultBookedDate?: Date;
  isOpen: boolean;
  minExperience?: UserExperience;
  modality?: ISpotModalityBase;
  onClose: () => void;
}

const registerBookingFormSchema = Yup.object().shape({
  bookedDate: Yup.date()
    .required('Requerido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  endTime: Yup.string().when('startTime', {
    is: (val?: string) => !!val,
    then: Yup.string()
      .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
      .nullable()
      .test(
        'is-greater',
        'Horário final deve ser maior que o inicial',
        function isSameOrAfter(value) {
          return moment(value, 'HH:mm').isSameOrAfter(
            moment(this.parent.startTime, 'HH:mm'),
          );
        },
      )
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.string()
      .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  itemsId: Yup.array()
    .nullable()
    .transform((value, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  startTime: Yup.string()
    .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  scheduleItemsId: Yup.array()
    .nullable()
    .transform((value, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  userId: Yup.string()
    .uuid()
    .required('Requerido')
    .transform((value) => value.value),
});

export const BookingRegisterModal = ({
  activityScheduleId,
  allowPartialTimeBookings = false,
  defaultBookedDate,
  isOpen,
  minExperience,
  modality,
  onClose,
}: IBookingRegisterModalProps): JSX.Element => {
  const { activity } = useActivity();
  const toast = useToast();

  const { push } = useHistory();

  const formRef = useRef<HTMLElement & HTMLFormElement>(null);

  const [includeDates, setIncludeDates] = useState<Date[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [scheduleItems, setScheduleItems] = useState<
    IBookingRegisterModalScheduleItem[]
  >([]);
  const [itemsSelectOptions, setItemsSelectOptions] = useState<SelectOption[]>(
    [],
  );
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [bookingQueueData, setBookingQueueData] =
    useState<IBookingQueueData | null>(null);
  const [isVerified, setIsVerified] = useState(false);

  const scheduleItemsSelectOptions = useMemo(
    () =>
      scheduleItems.map((scheduleItem) => ({
        label: scheduleItem.formattedPrice
          ? scheduleItem.name.concat(` - ${scheduleItem.formattedPrice}`)
          : scheduleItem.name,
        value: scheduleItem.id,
      })),
    [scheduleItems],
  );

  const {
    register,
    formState,
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(registerBookingFormSchema),
  });

  const { errors } = formState;

  const selectedBookedDateTime: Date | undefined = watch('bookedDate');

  const selectedBookedDate = selectedBookedDateTime
    ? selectedBookedDateTime.toLocaleDateString('fr-CA', {
        timeZone: 'America/Sao_Paulo',
      })
    : undefined;

  useEffect(() => {
    async function loadSchedules(activityId: string): Promise<void> {
      if (activityScheduleId) {
        try {
          const eventsSchedule = await listWeekdaysActivitiesSchedulesService({
            activityId,
            activityScheduleId,
            selectedMonth,
            showExpired: false,
          });

          const scheduleMonthDaysAvailable = eventsSchedule.map((event) => {
            const [year, month, day] = event.date.split('-');

            return new Date(+year, +month - 1, +day);
          });

          setIncludeDates(scheduleMonthDaysAvailable);

          if (defaultBookedDate) {
            const defaultDate = scheduleMonthDaysAvailable.find((date) =>
              isSameDay(date, defaultBookedDate),
            );

            if (!defaultDate) {
              setError('bookedDate', { message: 'Data não disponível' });
            }

            setValue('bookedDate', defaultDate);
          }
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao carregar dados',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao carregar as datas disponíveis, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    }

    if (activity) {
      loadSchedules(activity.id);
    }
  }, [
    activity,
    selectedYear,
    selectedMonth,
    toast,
    activityScheduleId,
    defaultBookedDate,
    setValue,
    setError,
  ]);

  useEffect(() => {
    const loadScheduleItems = async (id: string): Promise<void> => {
      try {
        const scheduleItemsList = await listActivityScheduleItemsService({
          scheduleId: id,
          bookedDate: selectedBookedDate,
          isActive: true,
          availableVacanciesOnly: true,
          withPendingBookings: true,
        });

        setScheduleItems(
          scheduleItemsList.map((scheduleItem) => ({
            ...scheduleItem,
            formattedPrice: scheduleItem.price
              ? maskMoney(scheduleItem.price)
              : undefined,
          })),
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os opcionais do evento, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    };

    if (activityScheduleId) {
      loadScheduleItems(activityScheduleId);
    }
  }, [activityScheduleId, selectedBookedDate, toast]);

  useEffect(() => {
    if (activity?.items.length) {
      setItemsSelectOptions(
        activity.items
          .filter((item) => item.isActive)
          .map((item) => ({
            label: item.name,
            value: item.id,
          })),
      );
    }
  }, [activity?.items]);

  const handleLoadUserSelectOption = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      if (!activity) {
        return [];
      }

      const { items: users } = await listUsersService({
        name,
        featureGroups: ['MEMBER', 'GUEST', 'DEPENDANT'],
        minExperience,
        ventureId: activity.spot.ventureId,
        limit: 4,
      });

      const parsedUsersSelectOption: AsyncSelectOption[] = [
        ...users.map((user) => ({
          label: user.name,
          value: user.id,
        })),
      ];

      return parsedUsersSelectOption;
    },
    [activity, minExperience],
  );

  const handleToggleConfirmationModal = useCallback(() => {
    setIsConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleBookingConfirm = useCallback(() => {
    setIsVerified(true);
  }, []);

  const handleBookingQueueConfirm = useCallback(async () => {
    if (!bookingQueueData) {
      return;
    }

    try {
      const bookingQueue = await createBookingQueuesService(
        bookingQueueData.queueData,
      );

      toast({
        title: 'Cadastrado com sucesso',
        description: 'A reserva foi cadastrada corretamente na fila.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      push('/booking-queues/details', {
        bookingQueueId: bookingQueue.id,
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha no cadastro',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao cadastrar a reserva na fila, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [bookingQueueData, push, toast]);

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [isVerified]);

  const handleBookingSubmit: SubmitHandler<NewBookingFormData> = useCallback(
    async ({
      bookedDate,
      description,
      endTime,
      itemsId,
      scheduleItemsId,
      startTime,
      userId,
    }) => {
      if (activity) {
        const selectedItems = activity.items.filter((item) =>
          itemsId?.includes(item.id),
        );

        const selectedScheduleItems = scheduleItems
          .filter((scheduleItem) => scheduleItemsId?.includes(scheduleItem.id))
          .map(({ formattedPrice: _, ...scheduleItem }) => scheduleItem);

        const bookedDateString = bookedDate.toLocaleDateString('fr-CA', {
          timeZone: 'America/Sao_Paulo',
        });

        try {
          const booking = await createBookingsService({
            activityScheduleId,
            bookedDate,
            description,
            endTime: new Date(`${bookedDateString}T${endTime}:00.000Z`),
            items: selectedItems,
            scheduleItems: selectedScheduleItems,
            isVerified,
            startTime: new Date(`${bookedDateString}T${startTime}:00.000Z`),
            userId,
          });

          toast({
            title: 'Cadastrado com sucesso',
            description: 'A reserva foi cadastrada corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          setIsVerified(false);

          setIsConfirmationModalVisible(false);

          push('/bookings/details', {
            bookingId: booking.id,
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            if (err.response?.data.message === 'spot-already-booked') {
              setIsConfirmationModalVisible(true);

              return;
            }

            if (
              err.response?.data.message &&
              [
                'booking-no-vacancies-available',
                'user-max-day-bookings',
                'user-guest-max-day-bookings',
              ].includes(err.response.data.message)
            ) {
              setBookingQueueData({
                errorMessage: err.response.data.message,
                queueData: {
                  activityId: activity.id,
                  activityScheduleId,
                  bookedDate,
                  description,
                  items: selectedItems,
                  modalities: modality ? [modality] : undefined,
                  userId,
                },
              });

              return;
            }

            toast({
              title: 'Falha no cadastro',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao cadastrar a reserva, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [
      activity,
      scheduleItems,
      activityScheduleId,
      isVerified,
      toast,
      push,
      modality,
    ],
  );

  const handleMonthChange = useCallback((date: Date) => {
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
  }, []);

  const handleCloseModal = useCallback(() => {
    reset();

    onClose();
  }, [onClose, reset]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ConfirmationModal
        isOpen={isConfirmationModalVisible}
        onClose={handleToggleConfirmationModal}
        onConfirm={handleBookingConfirm}
        message="Já existe reservas pendentes para esse membro nesse local, deseja
        criar nova reserva?"
        title="Confirmação de reserva"
      />

      {!!bookingQueueData && (
        <ConfirmationModal
          isOpen={!!bookingQueueData}
          onClose={() => setBookingQueueData(null)}
          onConfirm={handleBookingQueueConfirm}
          message={ErrorMessage[bookingQueueData.errorMessage]}
          title="Confirmação de reserva na fila"
        />
      )}

      <ModalOverlay />

      <ModalContent
        as="form"
        ref={formRef}
        onSubmit={handleSubmit(handleBookingSubmit)}
      >
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Cadastrar reserva
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            {!!modality && (
              <Heading size="md" fontWeight="normal" color="blue.500">
                <HStack>
                  <Text>Modalidade:</Text>

                  <Text>{modality.title}</Text>
                </HStack>
              </Heading>
            )}

            <DatePicker
              label="Data"
              isDisabled={!!defaultBookedDate}
              includeDates={includeDates}
              isClearable={false}
              minDate={new Date()}
              onMonthChange={handleMonthChange}
              control={control}
              error={errors.bookedDate}
              {...register('bookedDate')}
            />

            <AsyncSelect
              label="Membro"
              name="userId"
              loadOptions={handleLoadUserSelectOption}
              control={control}
              error={errors.userId}
            />

            {!!allowPartialTimeBookings && (
              <HStack spacing="8">
                <MaskedInput
                  label="Horário início"
                  mask="time"
                  error={errors.startTime}
                  {...register('startTime')}
                />

                <MaskedInput
                  label="Horário fim"
                  mask="time"
                  error={errors.endTime}
                  {...register('endTime')}
                />
              </HStack>
            )}

            {!!activity?.items.length && (
              <ReactMultiSelect
                label="Opcionais da atividade"
                name="itemsId"
                options={itemsSelectOptions}
                control={control}
                error={errors.itemsId}
              />
            )}

            {!!scheduleItemsSelectOptions?.length && (
              <ReactMultiSelect
                label="Opcionais do evento"
                name="scheduleItemsId"
                options={scheduleItemsSelectOptions}
                control={control}
                error={errors.scheduleItemsId}
              />
            )}

            <MaskedInput
              label="Descrição"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.description}
              {...register('description')}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Box>
            <ButtonGroup mt="6" w="full" justifyContent="flex-end">
              <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
                Cancelar
              </Button>

              <Button
                colorScheme="green"
                type="submit"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
