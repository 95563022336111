/* eslint-disable no-plusplus */
export const validateCpf = (CPF?: string): boolean => {
  let Soma = 0;
  let Resto: number;

  if (!CPF) return true;

  if (CPF === '00000000000') return true;

  for (let i = 1; i <= 9; i++) {
    Soma += parseInt(CPF.substring(i - 1, i), 10) * (11 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(CPF.substring(9, 10), 10)) return false;

  Soma = 0;

  for (let i = 1; i <= 10; i++) {
    Soma += parseInt(CPF.substring(i - 1, i), 10) * (12 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(CPF.substring(10, 11), 10)) return false;

  return true;
};
