import { Box, Heading, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskDateTime } from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';
import {
  BookingQueueTable,
  IBookingQueueTableItem,
} from './components/BookingQueueTable';
import { AsyncSelectOption } from '../../../../../components/Form/AsyncSelect';
import { listActivitiesService } from '../../../../../services/Activities/ListActivitiesService';
import { listDateBookingQueuesService } from '../../../../../services/BookingQueues/ListDateBookingQueuesService';
import { updateBookingQueuesPriorityService } from '../../../../../services/BookingQueues/UpdateBookingQueuesPriorityService';

interface ILoadBookingQueuesProps {
  activityId?: string;
  activityName?: string;
  showQueuedBookingsOnly?: boolean;
  spotName?: string;
  userDocument?: string;
  userName?: string;
  sort?: 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const BookingQueueList = (): JSX.Element => {
  const toast = useToast();

  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [filterBy, setFilterBy] = useState('activityId');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [bookingQueuesList, setBookingQueuesList] = useState<
    IBookingQueueTableItem[]
  >([]);

  const loadBookingQueues = useCallback(
    async ({
      activityName,
      spotName,
      userDocument,
      userName,
    }: ILoadBookingQueuesProps) => {
      try {
        setLoading(true);

        const bookingQueues = await listDateBookingQueuesService({
          activityName,
          bookedDate: selectedDate.toLocaleDateString('fr-CA'),
          showQueuedBookingsOnly: true,
          spotName,
          userDocument: userDocument?.replace(/\D/g, ''),
          userName,
        });

        const parsedBookingQueues = bookingQueues.map((bookingQueue) => {
          const [bookedYear, bookedMonth, bookedDay] =
            bookingQueue.bookedDate.split('-');

          return {
            ...bookingQueue,
            formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
            formattedCreatedAt: maskDateTime(bookingQueue.createdAt),
            activitySchedule: bookingQueue.activitySchedule
              ? {
                  ...bookingQueue.activitySchedule,
                  formattedEndTime: bookingQueue.activitySchedule.endTime.slice(
                    0,
                    5,
                  ),
                  formattedStartTime:
                    bookingQueue.activitySchedule.startTime.slice(0, 5),
                }
              : undefined,
          };
        });

        setBookingQueuesList(parsedBookingQueues);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados das reservas, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [selectedDate, toast],
  );

  useEffect(() => {
    loadBookingQueues({
      ...currentFilters,
    });
  }, [loadBookingQueues, currentFilters]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleLoadActivitiesOptions = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      const { items: activities } = await listActivitiesService({
        name,
        limit: 4,
      });

      const parsedActivitiesSelectOptions: AsyncSelectOption[] = [
        ...activities.map((activity) => ({
          label: activity.name,
          value: activity.id,
        })),
      ];

      return parsedActivitiesSelectOptions;
    },
    [],
  );

  const handleSelectedDate = (date: Date): void => {
    setSelectedDate(date);
  };

  const handlePriority = async (bookingQueueId: string): Promise<void> => {
    await updateBookingQueuesPriorityService({
      bookingQueueId,
    });

    loadBookingQueues({
      ...currentFilters,
    });
  };

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Fila de Reservas
        </Heading>

        <BookingQueueTable
          mt="4"
          bookingQueues={bookingQueuesList}
          filterBy={filterBy}
          loadAsyncOptions={handleLoadActivitiesOptions}
          loading={loading}
          onChangeFilterBy={handleFilterBy}
          onChangePriority={handlePriority}
          onChangeSelectedDate={handleSelectedDate}
          onFilter={debounceHandleFilter}
          onFilterAsync={handleFilter}
          selectedDate={selectedDate}
        />
      </Box>
    </DefaultLayout>
  );
};
