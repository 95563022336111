import {
  Box,
  BoxProps,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useUser } from '../../../../../../../../../hooks/user';
import { UserBookingsList } from './components/UserBookingsList';
import { UserFeaturesTable } from './components/UserFeaturesTable';

export const UserDetailsTabs = (props: BoxProps): JSX.Element => {
  const { user } = useUser();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <Box {...props}>
      <Tabs
        index={tabIndex}
        onChange={handleTabChanges}
        variant="enclosed-colored"
        isLazy
      >
        <TabList>
          {user?.featureGroup?.key === 'SELLER' && <Tab>Reservas</Tab>}

          <Tab>Permissões</Tab>
        </TabList>

        <TabPanels mt="4">
          {user?.featureGroup?.key === 'SELLER' && (
            <TabPanel>
              <UserBookingsList />
            </TabPanel>
          )}

          <TabPanel>
            {!!user?.featureGroup && (
              <>
                <HStack>
                  <Text>Grupo de permissões:</Text>
                  <Text color="blue.500">{user.featureGroup.name}</Text>
                </HStack>

                <UserFeaturesTable features={user.featureGroup.features} />
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
