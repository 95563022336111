import { IActivityScheduleItemBase } from '../../models/activities';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IListActivityScheduleItemsProps {
  scheduleId: string;
  bookedDate?: string;
  isActive?: boolean;
  availableVacanciesOnly?: boolean;
  withPendingBookings?: boolean;
}

export type IActivityScheduleItemListItem = IActivityScheduleItemBase;

export const listActivityScheduleItemsService = async ({
  scheduleId,
  bookedDate,
  isActive,
  availableVacanciesOnly,
  withPendingBookings,
}: IListActivityScheduleItemsProps): Promise<
  IActivityScheduleItemListItem[]
> => {
  const { data } = await jhsfBookingsApi.get<IActivityScheduleItemListItem[]>(
    `/activities/schedules/${scheduleId}/items`,
    {
      params: {
        bookedDate,
        isActive,
        availableVacanciesOnly,
        withPendingBookings,
      },
    },
  );

  return data;
};
