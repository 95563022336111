import { IActivityBase } from '../../models/activities';
import { ISpotBase } from '../../models/spots';
import { IVentureBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IListActivityProps {
  name?: string;
  order?: 'ASC' | 'DESC';
  sort?: 'name' | 'isActive' | 'createdAt';
  limit?: number;
  page?: number;
}

interface IActivityListItemSpot extends ISpotBase {
  venture: IVentureBase;
}

export interface IActivityListItem extends IActivityBase {
  spot: IActivityListItemSpot;
}

export interface IActivityList {
  items: IActivityListItem[];
  pages: number;
  total: number;
}

export const listActivitiesService = async (
  filters?: IListActivityProps,
): Promise<IActivityList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          name: filters.name || undefined,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await jhsfBookingsApi.get<IActivityList>('/activities', {
    params,
  });
  return data;
};
