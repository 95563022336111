import { CalendarIcon } from '@chakra-ui/icons';
import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  HStack,
  Button,
  CircularProgress,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import { RiEyeLine, RiStarLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { IActivityScheduleBase } from '../../../../../../../models/activities';
import { UserExperience } from '../../../../../../../models/users';
import { IBookingQueueListItem } from '../../../../../../../services/BookingQueues/ListBookingQueuesService';
import { maskShortDate } from '../../../../../../../utils/formatters/handleMask';

interface IBookingQueueTableItemActivitySchedule extends IActivityScheduleBase {
  formattedEndTime: string;
  formattedStartTime: string;
}

export interface IBookingQueueTableItem extends IBookingQueueListItem {
  activitySchedule?: IBookingQueueTableItemActivitySchedule;
  formattedBookedDate: string;
  formattedCreatedAt: string;
}

interface IBookingQueueTableProps extends BoxProps {
  bookingQueues: IBookingQueueTableItem[];
  filterBy: string;
  loadAsyncOptions: (inputValue: string) => void;
  loading?: boolean;
  onChangeFilterBy: (value: string) => void;
  onChangePriority: (bookingQueueId: string) => void;
  onChangeSelectedDate: (date: Date) => void;
  onFilter: (value: string) => void;
  onFilterAsync: (value: string) => void;
  selectedDate: Date;
}

export const BookingQueueTable = ({
  bookingQueues,
  filterBy,
  loading,
  loadAsyncOptions,
  onChangeFilterBy,
  onChangePriority,
  onChangeSelectedDate,
  onFilter,
  onFilterAsync,
  selectedDate,
  ...rest
}: IBookingQueueTableProps): JSX.Element => {
  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome da atividade',
        value: 'activityId',
      },
      {
        label: 'Nome do local',
        value: 'spotName',
      },
      {
        label: 'Nome do membro',
        value: 'userName',
      },
      {
        label: 'Documento do membro',
        value: 'userDocument',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <HStack align="flex-end" spacing="4">
        <TableFilters
          filterBy={filterBy}
          filterByOptions={filterByOptions}
          filterInputType={filterBy === 'activityId' ? 'asyncSelect' : 'input'}
          loadAsyncOptions={loadAsyncOptions}
          onChangeFilterBy={onChangeFilterBy}
          onFilter={onFilter}
          onFilterAsync={onFilterAsync}
        />

        <HStack>
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            onChange={onChangeSelectedDate}
            minDate={new Date()}
            todayButton="Hoje"
            selected={selectedDate}
            customInput={
              <Button
                size="sm"
                alignItems="center"
                justifyContent="space-between"
              >
                <CalendarIcon mr="2" />

                <Text textTransform="capitalize" fontWeight="bold">
                  {maskShortDate(selectedDate)}
                </Text>
              </Button>
            }
          />

          {loading && (
            <CircularProgress isIndeterminate color="teal" size="2rem" />
          )}
        </HStack>
      </HStack>

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Atividade</Th>

            <Th>Membro</Th>

            <Th>Nível de experiência</Th>

            <Th>Local</Th>

            <Th textAlign="center">Data/horário da reserva</Th>

            <Th textAlign="center">Reservado em</Th>

            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {bookingQueues.map((bookingQueue) => (
            <Tr key={bookingQueue.id}>
              <Td fontWeight="bold">{bookingQueue.activity.name}</Td>

              <Td>{bookingQueue.user.name}</Td>

              <Td>{UserExperience[bookingQueue.user.experience]}</Td>

              <Td>{bookingQueue.activity.spot.name}</Td>

              <Td textAlign="center">
                <Box>
                  <Text>{bookingQueue.formattedBookedDate}</Text>
                  {bookingQueue.activitySchedule ? (
                    <Text>{`${bookingQueue.activitySchedule.formattedStartTime} - ${bookingQueue.activitySchedule.formattedEndTime}`}</Text>
                  ) : (
                    <Text color="red.500">Sem horário</Text>
                  )}
                </Box>
              </Td>

              <Td textAlign="center">
                <Text>{bookingQueue.formattedCreatedAt.split('-')[0]}</Text>
                <Text>{bookingQueue.formattedCreatedAt.split('-')[1]}</Text>
              </Td>

              <Td textAlign="right">
                <ButtonGroup>
                  <IconButton
                    aria-label="Priority"
                    size="sm"
                    icon={<Icon as={RiStarLine} fontSize="18" />}
                    fontSize="16"
                    colorScheme={
                      !bookingQueue.priorizedAt ? 'blackAlpha' : 'yellow'
                    }
                    onClick={() => onChangePriority(bookingQueue.id)}
                  />

                  <LinkButton
                    px={0}
                    to={{
                      pathname: '/booking-queues/details',
                      state: {
                        bookingQueueId: bookingQueue.id,
                      },
                    }}
                  >
                    <Icon as={RiEyeLine} fontSize="16" />
                  </LinkButton>
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
