import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
  VStack,
  Box,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { IActivityScheduleItemBase } from '../../../../../../../../../../../../../../../models/activities';
import {
  createActivityScheduleItemsService,
  INewActivityScheduleItem,
} from '../../../../../../../../../../../../../../../services/Activities/CreateActivityScheduleItemsService';
import { updateActivityScheduleItemsService } from '../../../../../../../../../../../../../../../services/Activities/UpdateActivityScheduleItemsService';
import { translateError } from '../../../../../../../../../../../../../../../utils/errors';
import { MaskedInput } from '../../../../../../../../../../../../../../../components/Form/MaskedInput';
import { ConfirmationModal } from '../../../../../../../../../../../../../../../components/ConfirmationModal';
import { NumberInput } from '../../../../../../../../../../../../../../../components/Form/NumberInput';
import {
  AsyncSelect,
  AsyncSelectOption,
} from '../../../../../../../../../../../../../../../components/Form/AsyncSelect';
import { Switch } from '../../../../../../../../../../../../../../../components/Form/Switch';
import { listUsersService } from '../../../../../../../../../../../../../../../services/Users/ListUsersService';
import { useActivity } from '../../../../../../../../../../../../../../../hooks/activity';
import {
  maskMoney,
  unmaskNumber,
} from '../../../../../../../../../../../../../../../utils/formatters/handleMask';
import { IUserBase } from '../../../../../../../../../../../../../../../models/users';
import { SelectOption } from '../../../../../../../../../../../../../../../components/Form/ReactSelect';

export interface IHandleActivityScheduleItem extends IActivityScheduleItemBase {
  user?: IUserBase;
}

interface IHandleActivityScheduleItemModalFormData {
  description?: string;
  isActive: boolean;
  name: string;
  price?: number;
  totalAmount: number;
  selectedUser?: SelectOption;
}

interface IHandleActivityScheduleItemModalProps {
  scheduleItem?: IHandleActivityScheduleItem;
  scheduleId: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (scheduleItem: INewActivityScheduleItem) => void;
}

const handleActivityScheduleItemModalFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  isActive: Yup.boolean().default(true),
  name: Yup.string().required('Requerido'),
  price: Yup.number()
    .integer('Valores inteiros')
    .min(0, 'Apenas valor maior ou igual a zero')
    .nullable()
    .transform((_, originalValue) =>
      originalValue === '' ? null : unmaskNumber(originalValue),
    ),
  totalAmount: Yup.number()
    .integer('Valores inteiros')
    .min(0, 'Apenas valor maior ou igual a zero')
    .nullable()
    .required('Requerido')
    .transform((_, originalValue) =>
      originalValue === '' ? null : unmaskNumber(originalValue),
    ),
  selectedUser: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().uuid().nullable().nullable(),
    })
    .nullable(),
});

export const HandleActivityScheduleItemModal = ({
  scheduleItem,
  scheduleId,
  isOpen,
  onClose,
  onSave,
}: IHandleActivityScheduleItemModalProps): JSX.Element => {
  const toast = useToast();

  const { activity } = useActivity();

  const formRef = useRef<HTMLElement & HTMLFormElement>(null);

  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const { register, formState, handleSubmit, reset, control } = useForm({
    resolver: yupResolver(handleActivityScheduleItemModalFormSchema),
  });

  const { errors } = formState;

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    reset({
      description: scheduleItem?.description,
      isActive: scheduleItem?.isActive,
      name: scheduleItem?.name,
      price:
        typeof scheduleItem?.price === 'number'
          ? maskMoney(scheduleItem.price)
          : undefined,
      totalAmount: scheduleItem?.totalAmount,
      selectedUser: scheduleItem?.user
        ? {
            label: scheduleItem.user.name,
            value: scheduleItem.userId,
          }
        : undefined,
    });
  }, [scheduleItem, reset]);

  const handleActivityScheduleItemSubmit: SubmitHandler<IHandleActivityScheduleItemModalFormData> =
    useCallback(
      async ({
        description,
        isActive,
        name,
        price,
        totalAmount,
        selectedUser,
      }) => {
        if (!scheduleItem) {
          try {
            const newActivityScheduleItem =
              await createActivityScheduleItemsService({
                scheduleId,
                description,
                isActive,
                name,
                price,
                totalAmount,
                userId: selectedUser?.value,
              });

            reset({
              description: null,
              isActive: undefined,
              name: undefined,
              price: null,
              totalAmount: null,
              selectedUser: null,
            });

            onSave(newActivityScheduleItem);

            toast({
              title: 'Cadastrado com sucesso',
              description: 'Item cadastrado corretamente.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha ao cadastrar',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao cadastrar o item, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        } else {
          try {
            const updatedActivityScheduleItem =
              await updateActivityScheduleItemsService({
                itemId: scheduleItem.id,
                description,
                isActive,
                isVerified,
                name,
                price,
                totalAmount,
                userId: selectedUser?.value,
              });

            reset({
              description: null,
              isActive: undefined,
              name: undefined,
              price: null,
              totalAmount: null,
              selectedUser: null,
            });

            onSave(updatedActivityScheduleItem);

            toast({
              title: 'Atualizado com sucesso',
              description: 'Item atualizado corretamente.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              if (err.response?.data.message === 'has-bookings-pending') {
                handleToggleVerifyConfirmationModal();

                return;
              }

              toast({
                title: 'Falha ao atualizar',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao atualizar o item, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        }
      },
      [
        scheduleItem,
        scheduleId,
        reset,
        onSave,
        toast,
        isVerified,
        handleToggleVerifyConfirmationModal,
      ],
    );

  const handleVerify = (): void => {
    setIsVerified(true);
    handleToggleVerifyConfirmationModal();
  };

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [isVerified]);

  const handleLoadUserSelectOption = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      if (!activity) {
        return [];
      }

      const { items: users } = await listUsersService({
        name,
        featureGroups: ['SELLER'],
        ventureId: activity.spot.ventureId,
        limit: 4,
      });

      const parsedUsersSelectOption: AsyncSelectOption[] = [
        ...users.map((user) => ({
          label: user.name,
          value: user.id,
        })),
      ];

      return parsedUsersSelectOption;
    },
    [activity],
  );

  const handleCloseModal = (): void => {
    reset({
      description: null,
      isActive: undefined,
      name: undefined,
      price: null,
      totalAmount: null,
      selectedUser: null,
    });

    onClose();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={handleToggleVerifyConfirmationModal}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar alterações?"
      />

      <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />

        <ModalContent
          as="form"
          ref={formRef}
          onSubmit={handleSubmit(handleActivityScheduleItemSubmit)}
        >
          <ModalHeader>
            <Heading size="lg" fontWeight="normal">
              Item
            </Heading>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <VStack spacing="8">
              <MaskedInput
                label="Nome"
                type="name"
                error={errors.name}
                {...register('name')}
              />

              <MaskedInput
                label="Descrição"
                error={errors.description}
                {...register('description')}
              />

              <MaskedInput
                label="Preço"
                mask="money"
                error={errors.price}
                {...register('price')}
              />

              <NumberInput
                label="Quantidade disponível"
                error={errors.totalAmount}
                {...register('totalAmount')}
              />

              <AsyncSelect
                label="Prestador de serviço"
                name="selectedUser"
                loadOptions={handleLoadUserSelectOption}
                control={control}
                error={errors.selectedUser}
              />

              <Box>
                <Switch
                  label="Ativo"
                  error={errors.isActive}
                  defaultChecked={!scheduleItem}
                  {...register('isActive')}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
                Cancelar
              </Button>

              <Button
                colorScheme="green"
                type="submit"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
