import { IActivityBase, IActivityScheduleBase } from '../../models/activities';
import { IBookingQueueBase } from '../../models/bookingQueues';
import { ISpotBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import { IUserCategoryBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IDetailedActivity extends IActivityBase {
  spot: ISpotBase;
}

interface IDetailedTitularUser extends IUserBase {
  category: IUserCategoryBase;
}

export interface IDayBookingQueue extends IBookingQueueBase {
  user: IUserBase;
  titularUser: IDetailedTitularUser;
  activity: IDetailedActivity;
  activitySchedule: IActivityScheduleBase;
}

interface IListDateBookingQueueProps {
  activityName?: string;
  bookedDate: string;
  showQueuedBookingsOnly?: boolean;
  spotName?: string;
  userDocument?: string;
  userName?: string;
}

export const listDateBookingQueuesService = async (
  filters: IListDateBookingQueueProps,
): Promise<IDayBookingQueue[]> => {
  const { data } = await jhsfBookingsApi.get<IDayBookingQueue[]>(
    '/booking-queues/date',
    {
      params: {
        activityName: filters.activityName || undefined,
        bookedDate: filters.bookedDate || undefined,
        showQueuedBookingsOnly: filters.showQueuedBookingsOnly,
        spotName: filters.spotName || undefined,
        userDocument: filters.userDocument || undefined,
        userName: filters.userName || undefined,
      },
    },
  );

  return data;
};
