import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Tr,
  Icon,
  ButtonGroup,
  IconButton,
  useToast,
  HStack,
  Button,
  Flex,
  Box,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RiCheckboxCircleLine,
  RiCheckboxBlankCircleLine,
  RiDeleteBinLine,
  RiEditLine,
  RiAddLine,
} from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../../../../../../../components/ConfirmationModal';
import { TableFilters } from '../../../../../../../../../../../../../components/TableFilters';
import { useAuth } from '../../../../../../../../../../../../../hooks/auth';
import { IActivityScheduleItemBase } from '../../../../../../../../../../../../../models/activities';
import { IUserBase } from '../../../../../../../../../../../../../models/users';
import deleteActivityScheduleItemsService from '../../../../../../../../../../../../../services/Activities/DeleteActivityItemsService copy';
import { showActivitySchedulesService } from '../../../../../../../../../../../../../services/Activities/ShowActivitySchedulesService';
import { translateError } from '../../../../../../../../../../../../../utils/errors';
import { maskMoney } from '../../../../../../../../../../../../../utils/formatters/handleMask';
import {
  HandleActivityScheduleItemModal,
  IHandleActivityScheduleItem,
} from './components/HandleActivityScheduleItemModal';

export interface IActivityScheduleItem extends IActivityScheduleItemBase {
  formattedPrice?: string;
  user?: IUserBase;
}

interface IActivityScheduleItemsModalProps {
  scheduleId: string;
  isOpen: boolean;
  onClose: () => void;
}
export const ActivityScheduleItemsModal = ({
  scheduleId,
  isOpen,
  onClose,
}: IActivityScheduleItemsModalProps): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const [scheduleItems, setScheduleItems] = useState<IActivityScheduleItem[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [handlingScheduleItem, setHandlingScheduleItem] =
    useState<IActivityScheduleItem>();
  const [searchParam, setSearchParam] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isHandleActivityItemModalVisible,
    setIsHandleActivityItemModalVisible,
  ] = useState(false);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleActivityItemModal = useCallback(() => {
    setIsHandleActivityItemModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const loadSchedule = async (id: string): Promise<void> => {
      setLoading(true);

      try {
        const schedule = await showActivitySchedulesService({
          scheduleId: id,
          showInactiveItems: true,
        });

        setScheduleItems(
          schedule.items.map((item) => ({
            ...item,
            formattedPrice:
              typeof item.price === 'number'
                ? maskMoney(item.price)
                : undefined,
          })),
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os opcionais da atividade, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        setLoading(false);
      }
    };

    loadSchedule(scheduleId);
  }, [scheduleId, toast]);

  const itemsFound = useMemo(() => {
    if (searchParam) {
      return scheduleItems.filter((scheduleItem) =>
        scheduleItem.name.match(new RegExp(searchParam, 'gi')),
      );
    }

    return scheduleItems;
  }, [scheduleItems, searchParam]);

  const handleItemSearch = useCallback((search: string) => {
    setSearchParam(search);
  }, []);

  const handleScheduleItemDeleteConfirmation = (
    item: IActivityScheduleItem,
  ): void => {
    setHandlingScheduleItem(item);

    handleToggleDeleteConfirmationModal();
  };

  const handleDeleteScheduleItem = useCallback(
    async (itemId: string): Promise<void> => {
      try {
        await deleteActivityScheduleItemsService({ itemId, isVerified });
        setHandlingScheduleItem(undefined);
        setIsVerified(false);
        setIsVerifyConfirmationModalVisible(false);

        setScheduleItems((prevState) =>
          prevState.filter((scheduleItem) => scheduleItem.id !== itemId),
        );

        toast({
          title: 'Excluído com sucesso',
          description: 'O evento foi excluído corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          if (err.response?.data.message === 'has-bookings-pending') {
            handleToggleDeleteConfirmationModal();
            handleToggleVerifyConfirmationModal();

            return;
          }

          toast({
            title: 'Falha ao excluir',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao excluir o item, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [
      handleToggleDeleteConfirmationModal,
      handleToggleVerifyConfirmationModal,
      isVerified,
      toast,
    ],
  );

  const handleVerify = (): void => {
    setIsVerified(true);
  };

  useEffect(() => {
    if (handlingScheduleItem && isVerified) {
      handleDeleteScheduleItem(handlingScheduleItem.id);
    }
  }, [handleDeleteScheduleItem, handlingScheduleItem, isVerified]);

  const handleActivityScheduleItem = (
    item: IHandleActivityScheduleItem,
  ): void => {
    if (handlingScheduleItem) {
      setScheduleItems((prevState) =>
        prevState.map((scheduleItem) =>
          scheduleItem.id === item.id
            ? {
                ...item,
                formattedPrice:
                  typeof item.price === 'number'
                    ? maskMoney(item.price)
                    : undefined,
              }
            : scheduleItem,
        ),
      );

      setHandlingScheduleItem(undefined);
    } else {
      setScheduleItems((prevState) => [
        {
          ...item,
          formattedPrice:
            typeof item.price === 'number' ? maskMoney(item.price) : undefined,
        },
        ...prevState,
      ]);
    }

    handleToggleHandleActivityItemModal();
  };

  const handleClose = useCallback(() => {
    setScheduleItems([]);
    setSearchParam(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      {handlingScheduleItem && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={() => {
            setHandlingScheduleItem(undefined);
            handleToggleDeleteConfirmationModal();
          }}
          onConfirm={() => handleDeleteScheduleItem(handlingScheduleItem.id)}
          title="Confirmar exclusão"
          message="Deseja realmente excluir?"
        />
      )}

      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={() => {
          setHandlingScheduleItem(undefined);
          handleToggleVerifyConfirmationModal();
        }}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar exclusão?"
      />

      <HandleActivityScheduleItemModal
        scheduleItem={handlingScheduleItem}
        scheduleId={scheduleId}
        isOpen={isHandleActivityItemModalVisible}
        onClose={() => {
          setHandlingScheduleItem(undefined);
          handleToggleHandleActivityItemModal();
        }}
        onSave={handleActivityScheduleItem}
      />

      <Modal
        size="7xl"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={handleClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <Heading size="lg" fontWeight="normal">
              Opcionais disponíveis
            </Heading>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <HStack justify="space-between" align="flex-end">
              <TableFilters
                searchLabel="Buscar por nome"
                onSearch={handleItemSearch}
              />

              {authenticatedUser.featureGroup.features.some((feature) =>
                [
                  'ACTIVITY_SCHEDULE_ITEM_FULL_ACCESS',
                  'ACTIVITY_SCHEDULE_ITEM_WRITE_ACCESS',
                ].includes(feature.key),
              ) && (
                <Button
                  size="sm"
                  colorScheme="green"
                  leftIcon={<Icon as={RiAddLine} fontSize="16" />}
                  onClick={handleToggleHandleActivityItemModal}
                >
                  Cadastrar novo
                </Button>
              )}
            </HStack>

            <Box h="100vh">
              {scheduleItems.length ? (
                <Table mt="4" colorScheme="blue">
                  <Thead>
                    <Tr>
                      <Th textAlign="center" w="4">
                        Ativo
                      </Th>
                      <Th>Nome</Th>
                      <Th>Descrição</Th>
                      <Th>Prestador de serviço</Th>
                      <Th>Preço</Th>
                      <Th>Quantidade</Th>
                      <Th textAlign="right" w="8" />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {itemsFound.map((item) => (
                      <Tr key={item.id}>
                        <Td textAlign="center">
                          {item.isActive ? (
                            <Icon as={RiCheckboxCircleLine} color="green" />
                          ) : (
                            <Icon
                              as={RiCheckboxBlankCircleLine}
                              color="gray.400"
                            />
                          )}
                        </Td>

                        <Td>
                          <Text fontWeight="bold">{item.name}</Text>
                        </Td>

                        <Td>
                          <Text>{item.description}</Text>
                        </Td>

                        <Td>
                          <Text>{item.user?.name}</Text>
                        </Td>

                        <Td>
                          <Text>{item.formattedPrice}</Text>
                        </Td>

                        <Td>
                          <Text>{item.totalAmount}</Text>
                        </Td>

                        <Td textAlign="right">
                          <ButtonGroup>
                            {authenticatedUser.featureGroup.features.some(
                              (feature) =>
                                [
                                  'ACTIVITY_SCHEDULE_ITEM_FULL_ACCESS',
                                  'ACTIVITY_SCHEDULE_ITEM_WRITE_ACCESS',
                                ].includes(feature.key),
                            ) && (
                              <IconButton
                                aria-label="Edit"
                                size="sm"
                                icon={<Icon as={RiEditLine} />}
                                fontSize="16"
                                color="white"
                                colorScheme="yellow"
                                onClick={() => {
                                  setHandlingScheduleItem(item);
                                  handleToggleHandleActivityItemModal();
                                }}
                              />
                            )}

                            {authenticatedUser.featureGroup.features.some(
                              (feature) =>
                                [
                                  'ACTIVITY_SCHEDULE_ITEM_FULL_ACCESS',
                                  'ACTIVITY_SCHEDULE_ITEM_DELETE_ACCESS',
                                ].includes(feature.key),
                            ) && (
                              <IconButton
                                aria-label="Delete"
                                size="sm"
                                icon={<Icon as={RiDeleteBinLine} />}
                                fontSize="16"
                                colorScheme="red"
                                onClick={() =>
                                  handleScheduleItemDeleteConfirmation(item)
                                }
                              />
                            )}
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Flex w="full" h="24" justify="center" align="center">
                  {loading ? (
                    <Text color="gray.600">Carregando...</Text>
                  ) : (
                    <Text color="gray.600">Nenhum item cadastrado</Text>
                  )}
                </Flex>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
