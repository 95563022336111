import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { useAuth } from '../../../../../hooks/auth';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import deleteUserAvatarsService from '../../../../../services/Users/DeleteUserAvatarsService';
import { updateUsersService } from '../../../../../services/Users/UpdateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import {
  maskCnpj,
  maskCpf,
  maskZipCode,
} from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import {
  IFeatureGroupListItem,
  listFeatureGroupsService,
} from '../../../../../services/FeatureGroups/ListFeatureGroupsService';
import { validateCpf } from '../../../../../utils/validateCPF';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { IAddressBase } from '../../../../../models/address';
import AddressForm, {
  addressValidation,
} from '../../../../../components/AddressForm';

type UpdateUserFormData = {
  address?: IAddressBase;
  bio?: string;
  cpf?: string;
  cnpj?: string;
  companyName?: string;
  email: string;
  name: string;
  phone?: string;
  featureGroupId: string;
  tradeName?: string;
  ventureId: string;
};

interface ILocationState {
  userId: string;
}

const userUpdateFormSchema = Yup.object().shape({
  address: Yup.object().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: addressValidation.required('Endereço requerido'),
    otherwise: Yup.object()
      .nullable()
      .transform((_, originalValue) => {
        const teste = Object.values(originalValue).some((key) => !!key)
          ? originalValue
          : null;

        return teste;
      }),
  }),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cpf: Yup.string()
    .length(11, 'Valor inválido, requerido 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  cnpj: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('CNPJ requerido')
      .length(14, 'Valor inválido, requerido 14 dígitos')
      .nullable()
      .transform((_, originalValue) =>
        originalValue.replace(/\D/g, '').length
          ? originalValue.replace(/\D/g, '')
          : null,
      ),
    otherwise: Yup.string()
      .length(14, 'Valor inválido, requerido 14 dígitos')
      .nullable()
      .transform((_, originalValue) =>
        originalValue.replace(/\D/g, '').length
          ? originalValue.replace(/\D/g, '')
          : null,
      ),
  }),
  companyName: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('Nome da empresa requerido')
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  featureGroupKey: Yup.string().required('Requerido'),
  name: Yup.string().required('Nome requerido'),
  phone: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('Telefone requerido')
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  tradeName: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ventureId: Yup.string().required('Requerido'),
});

export const UserUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { updateUser, user } = useAuth();

  const { state } = useLocation<ILocationState>();

  const [updatingUser, setUpdatingUser] = useState<IDetailedUser>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [featureGroups, setFeatureGroups] = useState<IFeatureGroupListItem[]>(
    [],
  );
  const [featureGroupsSelectOptions, setFeatureGroupsSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [venturesSelectOptions, setVenturesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    watch,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(userUpdateFormSchema),
  });

  const { errors } = formState;
  const { userId } = state;

  const selectedFeatureGroupKey = watch('featureGroupKey');

  const selectedFeatureGroupId = useMemo(
    () =>
      featureGroups.find(
        (featureGroup) => featureGroup.key === selectedFeatureGroupKey,
      )?.id,
    [featureGroups, selectedFeatureGroupKey],
  );

  useEffect(() => {
    async function loadUser(): Promise<void> {
      try {
        const userData = await showUsersService(userId);
        setAvatarUrl(userData.avatarUrl || undefined);

        setUpdatingUser(userData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados do funcionário, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadUser();
  }, [userId, reset, toast]);

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items: ventures } = await listVenturesService();
      const venturesData: SelectOption[] = ventures.map((venture) => ({
        label: venture.name,
        value: venture.id,
      }));
      setVenturesSelectOptions(venturesData);
    }

    loadVentures();
  }, []);

  useEffect(() => {
    if (updatingUser) {
      reset({
        address: {
          city: updatingUser.address?.city,
          complement: updatingUser.address?.complement,
          country: updatingUser.address?.country,
          neighborhood: updatingUser.address?.neighborhood,
          number: updatingUser.address?.number,
          zipCode: updatingUser.address?.zipCode
            ? maskZipCode(updatingUser.address?.zipCode)
            : undefined,
          state: updatingUser.address?.state,
          street: updatingUser.address?.street,
        },
        bio: updatingUser.bio,
        cpf: updatingUser.cpf ? maskCpf(updatingUser.cpf) : undefined,
        cnpj: updatingUser.cnpj ? maskCnpj(updatingUser.cnpj) : undefined,
        companyName: updatingUser.companyName,
        email: updatingUser.email,
        isBlocked: updatingUser.isBlocked,
        name: updatingUser.name,
        phone: updatingUser.phone,
        tradeName: updatingUser.tradeName,
        featureGroupKey: updatingUser.featureGroup?.key,
        ventureId: updatingUser.venture?.id || ' ',
      });
    }
  }, [reset, updatingUser]);

  useEffect(() => {
    async function loadFeatureGroups(): Promise<void> {
      const { items: featureGroupList } = await listFeatureGroupsService({});

      const forbiddenFeatureGroups = [
        'APP_STORE_USER',
        'DEPENDANT',
        'MEMBER',
        'GUEST',
      ];

      const validFeatureGroups = featureGroupList.filter(
        (featureGroup) =>
          featureGroup.authLevel >= user.featureGroup.authLevel &&
          !forbiddenFeatureGroups.includes(featureGroup.key),
      );

      const parsedFeatureGroupsSelectOptions = validFeatureGroups.map(
        (featureGroup) => ({
          label: featureGroup.name,
          value: featureGroup.key,
        }),
      );

      setFeatureGroups(validFeatureGroups);
      setFeatureGroupsSelectOptions(parsedFeatureGroupsSelectOptions);
    }

    loadFeatureGroups();
  }, [user.featureGroup.authLevel]);

  useEffect(() => {
    if (selectedFeatureGroupKey !== 'SELLER') {
      setValue('companyName', undefined);
      setValue('tradeName', undefined);
      setValue('cnpj', undefined);
    }
  }, [reset, selectedFeatureGroupKey, setValue]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(userId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();

    if (userId === user.id) {
      delete user.avatar;
      delete user.avatarUrl;

      updateUser(user);
    }
  }, [userId, handleToggleDeleteConfirmationModal, updateUser, user]);

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = useCallback(
    async ({
      address,
      bio,
      cpf,
      cnpj,
      companyName,
      email,
      name,
      phone,
      tradeName,
      ventureId,
    }) => {
      const cleanVentureId = ventureId.replace(/\s/g, '');

      if (selectedFeatureGroupKey !== 'MASTER' && !cleanVentureId) {
        setError('ventureId', {
          message: 'Requerido especificar um empreendimento',
        });

        return;
      }

      try {
        const updatedUser = await updateUsersService({
          userId,
          address,
          bio,
          cpf,
          cnpj,
          companyName,
          email,
          name,
          phone,
          featureGroupId: selectedFeatureGroupId,
          tradeName,
          ventureId: cleanVentureId || null,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          const userWithUpdatedAvatar = await updateUserAvatarsService({
            userId,
            avatarData: formData,
          });

          if (userWithUpdatedAvatar.id === user.id) {
            updateUser(userWithUpdatedAvatar);
          }
        } else if (updatedUser.id === user.id) {
          updateUser(updatedUser);
        }

        toast({
          title: 'Editado com sucesso',
          description: 'O funcionário foi editado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao editar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao editar o funcionário, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [
      selectedFeatureGroupKey,
      setError,
      userId,
      selectedFeatureGroupId,
      avatar,
      user.id,
      toast,
      goBack,
      updateUser,
    ],
  );

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateUser)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar funcionário
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          {!user.ventureId ? (
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="6"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 4]}>
                <ReactSelect
                  label="Tipo"
                  name="featureGroupKey"
                  options={featureGroupsSelectOptions}
                  control={control}
                  error={errors.featureGroupKey}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 8]}>
                <ReactSelect
                  label="Empreendimento"
                  name="ventureId"
                  options={
                    selectedFeatureGroupKey === 'MASTER'
                      ? [
                          { label: 'Todos', value: ' ' },
                          ...venturesSelectOptions,
                        ]
                      : venturesSelectOptions
                  }
                  control={control}
                  error={errors.ventureId}
                />
              </GridItem>
            </Grid>
          ) : (
            <ReactSelect
              label="Tipo"
              name="featureGroupKey"
              options={featureGroupsSelectOptions}
              control={control}
              error={errors.featureGroupKey}
            />
          )}

          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="6"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 12, 8]}>
              <MaskedInput
                label="Nome completo"
                error={errors.name}
                {...register('name')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 4]}>
              {selectedFeatureGroupKey === 'SELLER' ? (
                <MaskedInput
                  label="CNPJ"
                  mask="cnpj"
                  error={errors.cnpj}
                  {...register('cnpj')}
                />
              ) : (
                <MaskedInput
                  label="CPF"
                  mask="cpf"
                  error={errors.cpf}
                  {...register('cpf')}
                />
              )}
            </GridItem>
          </Grid>

          <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />
          </SimpleGrid>

          {selectedFeatureGroupKey === 'SELLER' && (
            <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
              <MaskedInput
                label="Nome da empresa"
                error={errors.companyName}
                {...register('companyName')}
              />

              <MaskedInput
                label="Nome fantasia"
                error={errors.tradeName}
                {...register('tradeName')}
              />
            </SimpleGrid>
          )}

          <MaskedInput
            label="Biografia"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        {selectedFeatureGroupKey === 'SELLER' && (
          <>
            <Divider my="6" borderColor="gray.300" />

            <AddressForm errors={errors} register={register} />
          </>
        )}

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>

            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
