import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  IconButton,
  ButtonGroup,
  Button,
  HStack,
  Text,
  useToast,
  Switch,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  RiAddLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
  RiHandCoinLine,
  RiLink,
} from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../../../../../components/ConfirmationModal';
import { HandleBookingQueueModal } from '../../../../../../../../../../../components/HandleBookingQueueModal';
import { useActivity } from '../../../../../../../../../../../hooks/activity';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { UserExperience } from '../../../../../../../../../../../models/users';
import deleteActivitySchedulesService from '../../../../../../../../../../../services/Activities/DeleteActivitySchedulesService';
import { listActivitiesSchedulesService } from '../../../../../../../../../../../services/Activities/ListActivitySchedulesService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { maskMoney } from '../../../../../../../../../../../utils/formatters/handleMask';
import { BookingRegisterModal } from '../BookingRegisterModal';
import { ActivityScheduleItemsModal } from './components/ActivityScheduleItemsModal';
import {
  HandleActivityScheduleModal,
  IActivitySchedule,
} from './components/HandleActivityScheduleModal';

export const ActivitySchedules = (props: BoxProps): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const { activity } = useActivity();

  const [activitySchedules, setActivitySchedules] = useState<
    IActivitySchedule[]
  >([]);
  const [showExpired, setShowExpired] = useState(false);
  const [handlingActivitySchedule, setHandlingActivitySchedule] =
    useState<IActivitySchedule>();
  const [isBookingRegisterModalVisible, setIsBookingRegisterModalVisible] =
    useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isHandleActivityScheduleModalVisible,
    setIsHandleActivityScheduleModalVisible,
  ] = useState(false);
  const [
    isHandleScheduleItemsModalVisible,
    setIsHandleScheduleItemsModalVisible,
  ] = useState(false);
  const [
    isHandleBookingQueueModalVisible,
    setIsHandleBookingQueueModalVisible,
  ] = useState(false);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleToggleBookingRegisterModal = useCallback(() => {
    setIsBookingRegisterModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleActivityScheduleModal = useCallback(() => {
    setIsHandleActivityScheduleModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleScheduleItemsModal = useCallback(() => {
    setIsHandleScheduleItemsModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleBookingQueueModal = useCallback(() => {
    setIsHandleBookingQueueModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    async function loadSchedules(): Promise<void> {
      if (activity) {
        try {
          const schedulesList = await listActivitiesSchedulesService({
            activityId: activity.id,
            showExpired,
          });

          setActivitySchedules(
            schedulesList.items.map((activitySchedule) => {
              const [startDateYear, startDateMonth, startDateDay] =
                activitySchedule.startDate.split('-');
              const [endDateYear, endDateMonth, endDateDay] =
                activitySchedule.endDate?.split('-') || [];

              return {
                ...activitySchedule,
                formattedEndDate: activitySchedule.endDate
                  ? `${endDateDay}/${endDateMonth}/${endDateYear}`
                  : '-',
                formattedEndTime: activitySchedule.endTime.slice(0, 5),
                formattedStartDate: `${startDateDay}/${startDateMonth}/${startDateYear}`,
                formattedStartTime: activitySchedule.startTime.slice(0, 5),
              };
            }),
          );
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao carregar dados',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao carregar os eventos, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    }

    loadSchedules();
  }, [activity, showExpired, toast]);

  const handleActivitySchedule = useCallback(
    (
      activitySchedule: IActivitySchedule,
      action: 'book' | 'delete' | 'update' | 'handle-items',
    ) => {
      setHandlingActivitySchedule(activitySchedule);

      switch (action) {
        case 'book':
          handleToggleBookingRegisterModal();

          break;

        case 'delete':
          handleToggleDeleteConfirmationModal();

          break;

        case 'update':
          handleToggleHandleActivityScheduleModal();

          break;

        case 'handle-items':
          handleToggleHandleScheduleItemsModal();

          break;

        default:
          break;
      }
    },
    [
      handleToggleBookingRegisterModal,
      handleToggleDeleteConfirmationModal,
      handleToggleHandleActivityScheduleModal,
      handleToggleHandleScheduleItemsModal,
    ],
  );

  const handleActivityScheduleModalSave = (
    activitySchedule: IActivitySchedule,
    action: 'create' | 'update',
  ): void => {
    switch (action) {
      case 'create':
        setActivitySchedules((prevState) => [activitySchedule, ...prevState]);

        break;

      case 'update':
        setActivitySchedules((prevState) =>
          prevState.map((schedule) =>
            schedule.id === activitySchedule.id
              ? { ...schedule, ...activitySchedule }
              : schedule,
          ),
        );

        break;

      default:
        break;
    }

    setHandlingActivitySchedule(undefined);
    handleToggleHandleActivityScheduleModal();
  };

  const handleDeleteActivitySchedule = useCallback(
    async (activityScheduleId: string) => {
      if (activity) {
        try {
          await deleteActivitySchedulesService({
            activityScheduleId,
            isVerified,
          });

          setHandlingActivitySchedule(undefined);
          setIsVerified(false);
          setIsVerifyConfirmationModalVisible(false);

          setActivitySchedules((prevState) =>
            prevState.filter((schedule) => schedule.id !== activityScheduleId),
          );

          toast({
            title: 'Excluído com sucesso',
            description: 'O evento foi excluído corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            if (err.response?.data.message === 'has-bookings-pending') {
              handleToggleDeleteConfirmationModal();
              handleToggleVerifyConfirmationModal();

              return;
            }

            toast({
              title: 'Falha ao excluir',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao excluir o evento, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [
      activity,
      handleToggleDeleteConfirmationModal,
      handleToggleVerifyConfirmationModal,
      isVerified,
      toast,
    ],
  );

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (handlingActivitySchedule && isVerified) {
      handleDeleteActivitySchedule(handlingActivitySchedule.id);
    }
  }, [handleDeleteActivitySchedule, handlingActivitySchedule, isVerified]);

  const handleCopyBookingUrl = useCallback(
    async (bookingUrl: string) => {
      try {
        await navigator.clipboard.writeText(bookingUrl);

        toast({
          title: 'Copiado com sucesso',
          description: 'O link foi copiado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao copiar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao copiar o link, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  return (
    <Box {...props}>
      {handlingActivitySchedule && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            handleToggleDeleteConfirmationModal();
          }}
          onConfirm={() =>
            handleDeleteActivitySchedule(handlingActivitySchedule.id)
          }
          title="Confirmar exclusão"
          message="Deseja realmente excluir?"
        />
      )}

      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={handleToggleVerifyConfirmationModal}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar ação?"
      />

      <HandleActivityScheduleModal
        activitySchedule={handlingActivitySchedule}
        isOpen={isHandleActivityScheduleModalVisible}
        onClose={() => {
          setHandlingActivitySchedule(undefined);
          handleToggleHandleActivityScheduleModal();
        }}
        onSave={handleActivityScheduleModalSave}
      />

      {!!handlingActivitySchedule?.id && (
        <ActivityScheduleItemsModal
          scheduleId={handlingActivitySchedule.id}
          isOpen={isHandleScheduleItemsModalVisible}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            handleToggleHandleScheduleItemsModal();
          }}
        />
      )}

      {!!activity && (
        <HandleBookingQueueModal
          activity={activity}
          isOpen={isHandleBookingQueueModalVisible}
          onClose={handleToggleHandleBookingQueueModal}
        />
      )}

      {!!handlingActivitySchedule && (
        <BookingRegisterModal
          activityScheduleId={handlingActivitySchedule.id}
          minExperience={handlingActivitySchedule.minUserExperience}
          isOpen={isBookingRegisterModalVisible}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            handleToggleBookingRegisterModal();
          }}
        />
      )}

      <HStack justify="space-between" px="4">
        <HStack>
          <Switch
            name="showExpired"
            id="showExpired"
            isChecked={showExpired}
            onChange={() => setShowExpired((prevState) => !prevState)}
          />

          <Text htmlFor="showExpired">Exibir expirados</Text>
        </HStack>

        <ButtonGroup justifyContent="flex-end">
          {authenticatedUser.featureGroup.features.some((feature) =>
            [
              'BOOKING_QUEUE_FULL_ACCESS',
              'BOOKING_QUEUE_WRITE_ACCESS',
            ].includes(feature.key),
          ) && (
            <Button
              colorScheme="green"
              size="sm"
              leftIcon={<Icon as={RiAddLine} fontSize="16" />}
              onClick={handleToggleHandleBookingQueueModal}
            >
              Reserva na fila
            </Button>
          )}

          {authenticatedUser.featureGroup.features.some((feature) =>
            [
              'ACTIVITY_SCHEDULE_FULL_ACCESS',
              'ACTIVITY_SCHEDULE_WRITE_ACCESS',
            ].includes(feature.key),
          ) && (
            <Button
              colorScheme="green"
              size="sm"
              leftIcon={<Icon as={RiAddLine} fontSize="16" />}
              onClick={handleToggleHandleActivityScheduleModal}
            >
              Novo horário
            </Button>
          )}
        </ButtonGroup>
      </HStack>

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th textAlign="center">Ativo</Th>
            <Th>Data início</Th>
            <Th>Data fim</Th>
            <Th>Dias da semana</Th>
            <Th>Horário início</Th>
            <Th>Horário fim</Th>
            <Th textAlign="center">Vagas Total</Th>
            <Th>Modalidade</Th>
            <Th>Mín. Experiência</Th>
            <Th>Preço da reserva</Th>
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {activitySchedules.map((activitySchedule) => (
            <Tr key={activitySchedule.id}>
              <Td textAlign="center">
                {activitySchedule.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td>{activitySchedule.formattedStartDate}</Td>

              <Td>{activitySchedule.formattedEndDate}</Td>

              <Td>
                <HStack>
                  {['D', 'S', 'T', 'Q', 'Q', 'S', 'S'].map((weekDay, index) => (
                    <Text
                      key={weekDay.concat(String(index))}
                      color={
                        activitySchedule.daysOfWeek.some(
                          (scheduleDay) => scheduleDay === index,
                        )
                          ? 'gray.700'
                          : 'gray.400'
                      }
                    >
                      {weekDay}
                    </Text>
                  ))}
                </HStack>
              </Td>

              <Td>{activitySchedule.formattedStartTime}</Td>

              <Td>{activitySchedule.formattedEndTime}</Td>

              <Td textAlign="center">{activitySchedule.totalVacancies}</Td>

              <Td>{activitySchedule.modality?.title || '-'}</Td>

              <Td>{UserExperience[activitySchedule.minUserExperience]}</Td>

              <Td>
                {activitySchedule.bookingPrice
                  ? maskMoney(activitySchedule.bookingPrice)
                  : '-'}
              </Td>

              <Td textAlign="right">
                <ButtonGroup>
                  {authenticatedUser.featureGroup.features.some((feature) =>
                    ['BOOKING_FULL_ACCESS', 'BOOKING_WRITE_ACCESS'].includes(
                      feature.key,
                    ),
                  ) &&
                    !activitySchedule.isExpired && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="green"
                        onClick={() =>
                          handleActivitySchedule(activitySchedule, 'book')
                        }
                      >
                        Reservar
                      </Button>
                    )}

                  {!activitySchedule.isExpired &&
                    activitySchedule.allowLinkBookings && (
                      <IconButton
                        aria-label="Copy booking link"
                        size="sm"
                        icon={<Icon as={RiLink} />}
                        fontSize="16"
                        colorScheme="blue"
                        onClick={() =>
                          handleCopyBookingUrl(activitySchedule.bookingUrl)
                        }
                      />
                    )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_SCHEDULE_ITEM_FULL_ACCESS',
                      'ACTIVITY_SCHEDULE_ITEM_WRITE_ACCESS',
                      'ACTIVITY_SCHEDULE_ITEM_DELETE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Schedule items"
                      size="sm"
                      icon={<Icon as={RiHandCoinLine} />}
                      fontSize="16"
                      colorScheme="blue"
                      onClick={() =>
                        handleActivitySchedule(activitySchedule, 'handle-items')
                      }
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_SCHEDULE_FULL_ACCESS',
                      'ACTIVITY_SCHEDULE_WRITE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Edit"
                      size="sm"
                      icon={<Icon as={RiEditLine} />}
                      fontSize="16"
                      color="white"
                      colorScheme="yellow"
                      onClick={() =>
                        handleActivitySchedule(activitySchedule, 'update')
                      }
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_SCHEDULE_FULL_ACCESS',
                      'ACTIVITY_SCHEDULE_DELETE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<Icon as={RiDeleteBinLine} />}
                      fontSize="16"
                      colorScheme="red"
                      onClick={() =>
                        handleActivitySchedule(activitySchedule, 'delete')
                      }
                    />
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
