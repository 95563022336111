import {
  Box,
  Heading,
  VStack,
  Grid,
  GridItem,
  SimpleGrid,
  BoxProps,
} from '@chakra-ui/layout';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import * as Yup from 'yup';
import { MaskedInput } from '../Form/MaskedInput';

export const addressValidation = Yup.object({
  city: Yup.string()
    .nullable()
    .required('Cidade requerida')
    .matches(/^\D+$/, 'Valor inválido, apenas letras')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  complement: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  country: Yup.string()
    .nullable()
    .length(2, 'Valor inválido, requerido 2 dígitos')
    .required('País requerido')
    .matches(/^\D+$/, 'Valor inválido, apenas letras')
    .transform((value, originalValue) =>
      originalValue === '' ? null : String(value).toUpperCase(),
    ),
  neighborhood: Yup.string()
    .nullable()
    .required('Bairro requerido')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  number: Yup.string()
    .nullable()
    .required('Número requerido')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  zipCode: Yup.string()
    .nullable()
    .length(8, 'Valor inválido, requerido 8 dígitos')
    .required('Código postal requerido')
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  state: Yup.string()
    .nullable()
    .length(2, 'Valor inválido, requerido 2 dígitos')
    .required('Estado requerido')
    .matches(/^\D+$/, 'Valor inválido, apenas letras')
    .transform((value, originalValue) =>
      originalValue === '' ? null : String(value).toUpperCase(),
    ),
  street: Yup.string()
    .nullable()
    .required('Logradouro requerido')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

interface IAddressFormProps extends BoxProps {
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
}

const AddressForm = ({ errors, register }: IAddressFormProps): JSX.Element => (
  <Box>
    <Heading size="md" fontWeight="normal">
      Endereço
    </Heading>

    <VStack spacing="8" mt="8">
      <Grid
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(12, 1fr)',
        ]}
        gap="6"
        width="100%"
      >
        <GridItem colSpan={[12, 12, 10, 10]}>
          <MaskedInput
            label="Logradouro"
            error={errors.address?.street}
            {...register('address.street')}
          />
        </GridItem>

        <GridItem colSpan={[12, 12, 2, 2]}>
          <MaskedInput
            label="Número"
            error={errors.address?.number}
            {...register('address.number')}
          />
        </GridItem>
      </Grid>

      <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
        <MaskedInput
          label="Complemento"
          error={errors.address?.complement}
          {...register('address.complement')}
        />
        <MaskedInput
          label="Bairro"
          error={errors.address?.neighborhood}
          {...register('address.neighborhood')}
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
        <MaskedInput
          label="Cidade"
          error={errors.address?.city}
          {...register('address.city')}
        />
        <MaskedInput
          label="Estado"
          placeholder="SP"
          textTransform="uppercase"
          error={errors.address?.state}
          {...register('address.state')}
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
        <MaskedInput
          label="País"
          placeholder="BR"
          textTransform="uppercase"
          error={errors.address?.country}
          {...register('address.country')}
        />

        <MaskedInput
          label="Código postal"
          mask="cep"
          error={errors.address?.zipCode}
          {...register('address.zipCode')}
        />
      </SimpleGrid>
    </VStack>
  </Box>
);

export default AddressForm;
