import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import { IActivityScheduleItemBase } from '../../models/activities';

interface IUpdateActivityItemProps {
  itemId: string;
  description?: string;
  isActive: boolean;
  isVerified: boolean;
  name: string;
  price?: number;
  totalAmount: number;
  userId?: string;
}

export type IUpdatedActivityScheduleItem = IActivityScheduleItemBase;

export const updateActivityScheduleItemsService = async ({
  itemId,
  description,
  isActive,
  isVerified,
  name,
  price,
  totalAmount,
  userId,
}: IUpdateActivityItemProps): Promise<IUpdatedActivityScheduleItem> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedActivityScheduleItem>(
    `/activities/schedules/items/${itemId}`,
    {
      description,
      isActive,
      name,
      price,
      totalAmount,
      userId,
    },
    { params: { isVerified } },
  );

  return data;
};
