import { Box, Heading, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskDateTime } from '../../../../../utils/formatters/handleMask';
import { listBookingsService } from '../../../../../services/Bookings/ListBookingsService';
import { translateError } from '../../../../../utils/errors';
import {
  IBookingTableItem,
  BookingTable,
} from '../../../../../components/BookingTable';
import { useAuth } from '../../../../../hooks/auth';
import { useUser } from '../../../../../hooks/user';
import { createBookingExternalPaymentsService } from '../../../../../services/Bookings/CreateBookingExternalPaymentsService';

interface ILoadBookingsProps {
  activityName?: string;
  bookedDate?: string;
  spotName?: string;
  showPendingBookingsOnly?: boolean;
  userDocument?: string;
  userName?: string;
  sellerName?: string;
  sort?: 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const BookingList = (): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();
  const { user } = useUser();

  const [bookingsList, setBookingsList] = useState<IBookingTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('activityName');
  const [showPending, setShowPending] = useState(true);
  const [sortBy, setSortBy] = useState<'bookedDate' | 'createdAt'>(
    'bookedDate',
  );
  const [totalPages, setTotalPages] = useState<number>();

  const loadBookings = useCallback(
    async ({
      activityName,
      bookedDate,
      spotName,
      userDocument,
      userName,
      sellerName,
      showPendingBookingsOnly,
      order,
      page,
      sort = 'bookedDate',
    }: ILoadBookingsProps) => {
      try {
        const bookings = await listBookingsService({
          activityName,
          bookedDate,
          sellerId: user?.id,
          spotName,
          userDocument: userDocument?.replace(/\D/g, ''),
          userName,
          sellerName,
          showPendingBookingsOnly,
          limit: 20,
          page,
          sort,
          order,
        });

        const parsedBookings = bookings.items.map((booking) => {
          const [bookedYear, bookedMonth, bookedDay] =
            booking.bookedDate.split('-');

          return {
            ...booking,
            checkins: booking.checkins.map((checkin) => ({
              ...checkin,
              formattedCreatedAt: maskDateTime(checkin.createdAt),
            })),
            formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
            formattedCreatedAt: maskDateTime(booking.createdAt),
            formattedDeletedAt: booking.deletedAt
              ? maskDateTime(booking.deletedAt)
              : undefined,
            formattedEndTime: booking.endTime
              ? booking.endTime.slice(11, 16)
              : booking.activitySchedule.endTime.slice(0, 5),
            formattedStartTime: booking.startTime
              ? booking.startTime.slice(11, 16)
              : booking.activitySchedule.startTime.slice(0, 5),
          };
        });

        setTotalPages(bookings.pages);
        setBookingsList(parsedBookings);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados das reservas, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast, user?.id],
  );

  useEffect(() => {
    setTotalPages(1);
    setBookingsList([]);

    loadBookings({
      ...currentFilters,
      showPendingBookingsOnly: showPending,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [
    currentPage,
    loadBookings,
    currentOrdering,
    currentFilters,
    showPending,
    sortBy,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );
  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'bookedDate' | 'createdAt') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handlePayment = useCallback(
    async (bookingId: string) => {
      try {
        const paydBooking = await createBookingExternalPaymentsService(
          bookingId,
        );

        setBookingsList((prevState) =>
          prevState.map((booking) =>
            booking.id === paydBooking.id
              ? { ...booking, payment: paydBooking.payment }
              : booking,
          ),
        );

        toast({
          title: 'Transação concluída com sucesso',
          description: 'A transação foi efetuada corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha na transação',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao efetuar a transação, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleCheckin = (checkinBooking: IBookingTableItem): void => {
    setBookingsList((prevState) =>
      prevState.map((booking) =>
        booking.id === checkinBooking.id ? checkinBooking : booking,
      ),
    );
  };

  const filterByOptions =
    authenticatedUser.featureGroup.authLevel < 1
      ? [
          {
            label: 'Nome da atividade',
            value: 'activityName',
          },
          {
            label: 'Nome do local',
            value: 'spotName',
          },
          {
            label: 'Nome do seller',
            value: 'sellerName',
          },
          {
            label: 'Nome do membro',
            value: 'userName',
          },
          {
            label: 'Documento do membro',
            value: 'userDocument',
          },
          {
            label: 'Data da reserva',
            value: 'bookedDate',
          },
        ]
      : undefined;

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Reservas
        </Heading>

        <BookingTable
          mt="4"
          bookings={bookingsList}
          currentPage={currentPage}
          filterBy={filterBy}
          filterByOptions={filterByOptions}
          onChangeFilterBy={handleFilterBy}
          onCheckin={handleCheckin}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onPay={handlePayment}
          onSort={handleSortList}
          onShowPending={() => setShowPending((prevState) => !prevState)}
          order={currentOrdering}
          showPending={showPending}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
