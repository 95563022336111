import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IDeleteActivitiesServiceProps {
  activityId: string;
  isVerified?: boolean;
}

const deleteActivitiesService = async ({
  activityId,
  isVerified,
}: IDeleteActivitiesServiceProps): Promise<void> => {
  await jhsfBookingsApi.delete(`/activities/${activityId}`, {
    params: { isVerified },
  });
};

export default deleteActivitiesService;
