import {
  Box,
  HStack,
  Select,
  Text,
  Input,
  BoxProps,
  InputGroup,
  InputLeftAddon,
  Icon,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import ReactAsyncSelect from 'react-select/async';
import 'react-datepicker/dist/react-datepicker.css';
import { components, IndicatorProps, OptionTypeBase } from 'react-select';
import { HiChevronDown } from 'react-icons/hi';
import { useAsyncSelectStyles } from './useAsyncSelectStyles';

registerLocale('pt-BR', ptBr);

type Option = {
  label: string;
  value?: string;
};

interface ITableFiltersProps extends BoxProps {
  filterBy?: string;
  filterByOptions?: Option[];
  filterInputType?: 'input' | 'asyncSelect' | 'select' | 'datepicker';
  filterSelectOptions?: Option[];
  loadAsyncOptions?: (inputValue: string) => void;
  onChangeFilterBy?: (filter: string) => void;
  onFilter?: (value: string) => void;
  onFilterAsync?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onSearch?: (searchValue: string) => void;
  onSort?: (sort: string) => void;
  order?: 'ASC' | 'DESC';
  searchLabel?: string;
  sortBy?: string;
  sortByOptions?: Option[];
}

export const TableFilters = ({
  filterBy,
  filterByOptions = [],
  filterInputType = 'input',
  filterSelectOptions = [],
  loadAsyncOptions,
  onChangeFilterBy,
  onFilter,
  onFilterAsync,
  onOrder,
  onSearch,
  onSort,
  order = 'ASC',
  searchLabel,
  sortBy,
  sortByOptions = [],
  ...rest
}: ITableFiltersProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Date | null>();

  const orderOptions = useMemo(
    () => [
      { label: 'Crescente', value: 'ASC' },
      { label: 'Decrescente', value: 'DESC' },
    ],
    [],
  );

  const handleDateChange = useCallback(
    (value: Date | null) => {
      if (onFilter) {
        setSelectedDate(value);
        onFilter(value ? value.toISOString().split('T')[0] : '');
      }
    },
    [onFilter],
  );

  const DropdownIndicator = (
    props: IndicatorProps<OptionTypeBase, boolean>,
  ): React.ReactElement => (
    <components.DropdownIndicator {...props}>
      <Icon as={HiChevronDown} fontSize="19" />
    </components.DropdownIndicator>
  );

  const renderFilterInput = useCallback(
    (
      filter: (value: string) => void,
      loadOptions?: (inputValue: string) => void,
    ) => {
      switch (filterInputType) {
        case 'asyncSelect':
          return (
            <Box w="96">
              <ReactAsyncSelect
                cacheOptions
                isClearable
                onChange={(e) => filter(e?.value)}
                loadOptions={loadOptions}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                styles={useAsyncSelectStyles}
                placeholder=""
                loadingMessage={() => 'Carregando...'}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue ? 'Começe a digitar...' : 'Nenhum encontrado'
                }
                components={{ DropdownIndicator }}
              />
            </Box>
          );

        case 'select':
          return (
            <Select
              w="96"
              borderColor="gray.300"
              borderLeftWidth={0}
              bg="gray.100"
              focusBorderColor="blue.300"
              variant="outline"
              onChange={(e) => filter(e.target.value)}
            >
              {filterSelectOptions.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Select>
          );

        case 'datepicker':
          return (
            <ReactDatePicker
              placeholderText="Selecione uma data"
              dateFormat="dd/MM/yyyy"
              isClearable
              locale="pt-BR"
              selected={selectedDate}
              onChange={(value) => handleDateChange(value as Date | null)}
              todayButton="Hoje"
              customInput={
                <Input
                  w="96"
                  borderColor="gray.300"
                  borderLeftWidth={0}
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  size="sm"
                  onKeyUp={(e) => {
                    e.currentTarget.maxLength = 10;

                    e.currentTarget.value = e.currentTarget.value
                      .replace(/\D/g, '')
                      .replace(/(\d{2})(\d)/, '$1/$2')
                      .replace(/(\d{2})(\d)/, '$1/$2');
                  }}
                />
              }
            />
          );

        default:
          return (
            <Input
              w="96"
              borderColor="gray.300"
              borderLeftWidth={0}
              bg="gray.100"
              focusBorderColor="blue.300"
              variant="outline"
              onChange={(e) => filter(e.target.value)}
            />
          );
      }
    },
    [filterInputType, filterSelectOptions, handleDateChange, selectedDate],
  );

  return (
    <Box {...rest}>
      <HStack spacing="4" align="flex-end">
        {onSearch && (
          <Box>
            <Text>{searchLabel}</Text>
            <Input
              w="96"
              borderColor="gray.300"
              bg="gray.100"
              focusBorderColor="blue.300"
              size="sm"
              variant="outline"
              onChange={(e) => onSearch(e.target.value)}
            />
          </Box>
        )}

        {!!onChangeFilterBy && (
          <Box>
            <Text>Buscar por</Text>
            <InputGroup size="sm">
              <InputLeftAddon p="0">
                <Select
                  variant="outline"
                  borderColor="gray.300"
                  focusBorderColor="blue.300"
                  size="sm"
                  value={filterBy}
                  onChange={(e) => onChangeFilterBy(e.target.value)}
                >
                  {filterByOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              </InputLeftAddon>

              {filterInputType !== 'asyncSelect' &&
                !!onFilter &&
                renderFilterInput(onFilter)}

              {filterInputType === 'asyncSelect' &&
                !!onFilterAsync &&
                !!loadAsyncOptions &&
                renderFilterInput(onFilterAsync, loadAsyncOptions)}
            </InputGroup>
          </Box>
        )}

        {(!!onSort || !!onOrder) && (
          <Box>
            <HStack>
              {!!onSort && (
                <Box>
                  <Text>Ordenar por:</Text>
                  <Select
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    variant="outline"
                    size="sm"
                    value={sortBy}
                    onChange={(e) => onSort(e.target.value)}
                  >
                    {sortByOptions.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}

              {!!onOrder && (
                <Box>
                  <Text>Ordenação:</Text>
                  <Select
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    variant="outline"
                    size="sm"
                    value={order}
                    onChange={(e) => onOrder(e.target.value as 'ASC' | 'DESC')}
                  >
                    {orderOptions.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}
            </HStack>
          </Box>
        )}
      </HStack>
    </Box>
  );
};
